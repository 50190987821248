import { Button, DatePicker, Form, Input, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import Moment from "moment";
import axios from "axios";

const { Option } = Select;

const FormPasien = (props) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);
  const baseUrl = props.baseUrl; //"http://192.168.1.71:8000/api";

  const userid = localStorage.getItem("id");

  // const listPoli = ["MATA", "GIGI"];
  useEffect(() => {
    form.setFieldsValue({ nm_poli: props.poli });
    // setPoli(props.poli);
  }, [props.poli]);

  const handleGenNoKartuPasien = () => {
    let nama = form.getFieldValue("nm_pasien");
    axios
      .get(baseUrl + `/pasien/gennokartu/${nama}`)
      .then((res) => {
        // console.log(res.data);
        form.setFieldsValue({ no_kartu_pasien: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNoKartuPasien = (nik) => {
    return axios
      .get(baseUrl + "/getNoKartuPasien/" + nik)
      .then((res) => {
        props.setNoKartuPasien(res.data[0].no_kartu_pasien);

        props.onKeyChange("3"); //TAB 3 yang aktif
        setLoadings(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadings(false);
      });
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    setLoadings(true);
    var data = {
      nik: values.nik,
      no_kartu_pasien: values.no_kartu_pasien,
      nm_pasien: values.nm_pasien,
      tgl_lahir: Moment(values.tgl_lahir).format("YYYY-MM-DD"),
      jns_kel: values.gender,
      alamat: values.alamat,
      no_hp: values.no_hp,
      nm_poli: values.nm_poli,
      user_id: userid,
    };

    axios
      .post(baseUrl + "/pasien", data)
      .then((res) => {
        // eslint-disable-next-line no-unused-expressions
        // console.log(res);
        message.success("Data pasien berhasil disimpan");

        ///cari nomor kartu pasien, utk di pindahkan ke form kartu ambil antrian
        getNoKartuPasien(values.nik);

        props.setNamaPasien(values.nm_pasien);
        props.setAlamat(values.alamat);
        props.setPoli(values.nm_poli);
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response.data.nik[0]);
        message.error(error.response.data.nik[0]);
        setLoadings(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 7 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        // initialValues={{ nm_poli: props.poli }}
      >
        <Form.Item
          label="Poli"
          name="nm_poli"
          wrapperCol={{ span: 2 }}
          // rules={[{ required: true, message: "Masukkan alamat!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="NIK"
          name="nik"
          wrapperCol={{ span: 4 }}
          rules={[{ required: true, message: "Masukkan nik pasien!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="No. Kartu"
          name="no_kartu_pasien"
          wrapperCol={{ span: 2 }}
          rules={[{ required: true, message: "Masukkan nomor kartu pasien!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Nama Pasien"
          name="nm_pasien"
          rules={[{ required: true, message: "Masukkan nama pasien!" }]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="No. Kartu"
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            style={{
              display: "inline-block",
              width: "calc(50% -20px)",
            }}
          >
            <Button type="primary" onClick={handleGenNoKartuPasien}>
              Generate no.kartu Pas.
            </Button>
          </Form.Item>
          <Form.Item
            allowClear
            name="no_kartu_pasien"
            rules={[
              {
                required: true,
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(30% - 8px)",
              margin: "0 8px",
            }}
          >
            <Input style={{ width: 130 }} placeholder="No. Kartu Pasien" />
          </Form.Item>
        </Form.Item> */}

        <Form.Item
          label="Tanggal Lahir"
          style={{
            marginBottom: 0,
          }}
        >
          <Form.Item
            name="tgl_lahir"
            rules={[
              {
                required: true,
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
            }}
          >
            <DatePicker format={"DD-MM-YYYY"} />
          </Form.Item>
          <Form.Item
            name="gender"
            rules={[
              {
                required: true,
              },
            ]}
            style={{
              display: "inline-block",
              width: "calc(50% - 8px)",
              margin: "0 8px",
            }}
          >
            <Select
              placeholder="Jns kelamin pasien"
              // onChange={onGenderChange}
              allowClear
            >
              <Option value="male">male</Option>
              <Option value="female">female</Option>
            </Select>
          </Form.Item>
        </Form.Item>

        {/* <Form.Item
          label="Tanggal Lahir"
          name="tgl_lahir"
          rules={[{ required: true, message: "Masukkan tanggal lahir!" }]}
        >
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>

        <Form.Item
          name="gender"
          label="Gender"
          wrapperCol={{ span: 4 }}
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Pilih jenis kelamin pasien"
            // onChange={onGenderChange}
            allowClear
          >
            <Option value="male">male</Option>
            <Option value="female">female</Option>
          </Select>
        </Form.Item> */}

        <Form.Item
          label="Alamat"
          name="alamat"
          rules={[{ required: true, message: "Masukkan alamat!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="No. HP"
          name="no_hp"
          wrapperCol={{ span: 4 }}
          // rules={[{ required: true, message: "Masukkan alamat!" }]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="Poli"
          name="nm_poli"
          rules={[{ required: true, message: "Pilih nama poli!" }]}
        >
          <Select placeholder="Pilih nama Poli" allowClear>
            {listPoli.map((x, i) => (
              <Option key={i} value={x}>
                {x}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item wrapperCol={{ offset: 3, span: 7 }}>
          <Button type="primary" htmlType="submit" loading={loadings}>
            Daftar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormPasien;
