import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Grid,
  GridComponent,
  Inject,
  Search,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { Button, Form, Input, message, Modal, Select, Space, Spin } from "antd";
import axios from "axios";

const { TextArea } = Input;
const { Option } = Select;

const GridObat = (props) => {
  let baseUrl = props.baseUrl;
  let grid: Grid | null;
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();

  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataSupplier, setDataSupplier] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [recordId, setRecordId] = useState();
  const [isSave, setIsSave] = useState(false);
  const [isClick, setIsClick] = useState(false);

  ///mengambil data supplier
  useEffect(() => {
    let isMounted = true;

    axios
      .get(baseUrl + "/supplier")
      .then((res) => {
        if (isMounted) {
          setisLoading(false);
          setDataSupplier(res.data);
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, [dataSupplier]);

  ///mengambil data obat
  useEffect(() => {
    let isMounted = true;

    axios
      .get(baseUrl + "/barang")
      .then((res) => {
        if (isMounted) {
          setisLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, [data]);

  const toolbarku = [
    {
      text: "Add",
      // tooltipText: "Tambah Kapal",
      prefixIcon: "e-add",
      id: "addButton",
    },
    {
      text: "Edit",
      // tooltipText: "Ubah Kapal",
      prefixIcon: "e-edit",
      id: "editButton",
    },
    {
      text: "Delete",
      // tooltipText: "Hapus Kapal",
      prefixIcon: "e-delete",
      id: "deleteButton",
    },
    "Search",
  ];

  const toolbarClick = (args) => {
    if (grid) {
      if (args.item.id === "addButton") {
        // console.log("tombol add");
        setIsModalVisible(true);
      } else if (args.item.id === "editButton") {
        // console.log("tombol edit");
        setIsModalUpdateVisible(true);
      } else if (args.item.id === "deleteButton") {
        // console.log("tombol delete");
        if (data.length > 0) {
          setIsModalDeleteVisible(true);
        } else {
          message.warning("No record to delete");
        }
      }
    }
  };

  const rowSelected = () => {
    if (grid) {
      let record = grid.getSelectedRecords()[0];
      // console.log(record);
      setRecordId(record.id);
      formUpdate.setFieldsValue({
        id: record.id,
        kd_supplier: record.kd_supplier,
        kd_brg: record.kd_brg,
        nm_brg: record.nm_brg,
        satuan: record.satuan,
        hrg_jual: record.hrg_jual,
        ket: record.ket,
        user_id: localStorage.getItem("id"),
      });

      ///kirim datanya ke GridBrgIn
      props.getSelectedObat(record);
    }
  };

  const onFinish = (v) => {
    // console.log(v);

    setIsSave(true);
    let data = {
      kd_supplier: v.kd_supplier,
      kd_brg: v.kd_brg,
      nm_brg: v.nm_brg,
      satuan: v.satuan,
      hrg_jual: v.hrg_jual,
      ket: v.ket,
      user_id: localStorage.getItem("id"),
    };

    axios
      .post(baseUrl + "/barang", data)
      .then(() => {
        console.log("berhasil menambah data");
        message.success("Berhasil menambah data");
        setIsSave(false);
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e) => {
        console.log("gagal menambah data");
        message.error(e.message);
        setIsSave(false);
        setIsModalVisible(false);
      });
  };

  const onFinishFailed = (v) => {
    console.log(v);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalDeleteVisible(false);
    setIsModalUpdateVisible(false);
    form.resetFields();
    formUpdate.resetFields();
  };

  const onFinishUpdate = (v) => {
    setIsSave(true);
    let data = {
      kd_supplier: v.kd_supplier,
      kd_brg: v.kd_brg,
      nm_brg: v.nm_brg,
      satuan: v.satuan,
      hrg_jual: v.hrg_jual,
      ket: v.ket,
      user_id: localStorage.getItem("id"),
    };

    axios
      .put(baseUrl + `/barang/${recordId}`, data)
      .then(() => {
        console.log("berhasil merubah data");
        message.success("Berhasil merubah data");
        setIsSave(false);
        setIsModalUpdateVisible(false);
        formUpdate.resetFields();
      })
      .catch((e) => {
        console.log("gagal merubah data");
        message.error(e.message);
        setIsSave(false);
        setIsModalVisible(false);
      });
  };

  const handleOkDelete = () => {
    setIsClick(true);
    axios
      .delete(baseUrl + `/barang/${recordId}`)
      .then((res) => {
        message.success("Data berhasil dihapus !");
        setIsClick(false);
        setIsModalDeleteVisible(false);
      })
      .catch((err) => {
        message.error("Gagal menghapus data !");
        setIsClick(false);
        setIsModalDeleteVisible(false);
      });
  };

  if (isLoading) {
    return (
      <div className="example">
        <Spin />
      </div>
    );
  } else {
    return (
      <div>
        <GridComponent
          dataSource={data}
          rowHeight="30"
          height="200px"
          ref={(g) => (grid = g)}
          toolbar={toolbarku}
          toolbarClick={toolbarClick}
          rowSelected={rowSelected}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="ID"
              // textAlign="center"
              width="70"
            />

            <ColumnDirective
              field="kd_brg"
              headerText="CODE OBAT"
              // textAlign="center"
              width="200"
            />

            <ColumnDirective
              field="nm_brg"
              headerText="NAMA OBAT"
              // textAlign="center"
              width="200"
            />

            <ColumnDirective
              field="satuan"
              headerText="SATUAN"
              // textAlign="center"
              width="100"
            />

            <ColumnDirective
              field="hrg_jual"
              headerText="HRG JUAL"
              textAlign="Center"
              width="100"
            />

            <ColumnDirective
              field="ket"
              headerText="KETERANGAN"
              // textAlign="center"
              width="200"
            />

            <ColumnDirective
              field="kd_supplier"
              headerText="CODE SUPPLIER"
              // textAlign="center"
              width="120"
            />

            <ColumnDirective
              field="user_id"
              headerText="USER ID"
              // textAlign="center"
              width="120"
            />
          </ColumnsDirective>
          <Inject services={[Search, Toolbar, CommandColumn]} />
        </GridComponent>

        {/* MODAL */}
        <Modal
          title="Input Obat"
          visible={isModalVisible}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          footer={null}
          //   closable={false}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            // autoComplete="off"
            initialValues={{ hrg_jual: "0" }}
          >
            <Form.Item
              label="Supplier"
              name="kd_supplier"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Pilih Supplier"
                // onChange={handleOutletChange}
                allowClear
              >
                {dataSupplier.map((a) => (
                  <Option key={a.id} value={a.kd_supplier}>
                    {a.nm_supplier}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Kode Obat"
              name="kd_brg"
              wrapperCol={{ span: 10 }}
              rules={[{ required: true, message: "Masukkan kode obat !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Nama Obat"
              name="nm_brg"
              rules={[{ required: true, message: "Masukkan nama obat !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Satuan"
              name="satuan"
              wrapperCol={{ span: 10 }}
              rules={[{ required: true, message: "Masukkan satuan obat !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Harga Jual"
              name="hrg_jual"
              // rules={[{ required: true, message: "Masukkan harga obat !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Keterangan"
              name="ket"
              // rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 20 }}>
              <Space>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isSave}>
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
        {/* END MODAL */}

        {/* MODAL DELETE */}
        <Modal
          title="Confirmation Delete"
          visible={isModalDeleteVisible}
          onOk={handleOkDelete}
          onCancel={handleCancel}
          confirmLoading={isClick}
          width={300}
        >
          <p>Yakin data akan dihapus ?</p>
        </Modal>
        {/* END MODAL DELETE */}

        {/* MODAL UPDATE */}
        <Modal
          title="Update Obat"
          visible={isModalUpdateVisible}
          onCancel={() => {
            setIsModalUpdateVisible(false);
          }}
          footer={null}
          //   closable={false}
        >
          <Form
            form={formUpdate}
            name="basic-update"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            // initialValues={{ remember: true }}
            onFinish={onFinishUpdate}
            onFinishFailed={onFinishFailed}
            // autoComplete="off"
            initialValues={{ hrg_jual: "0" }}
          >
            <Form.Item
              hidden
              wrapperCol={{ span: 6 }}
              label="id"
              name="id"
              // rules={[{ required: true, message: "Masukkan kode supplier !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Supplier"
              name="kd_supplier"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Select
                placeholder="Pilih Supplier"
                // onChange={handleOutletChange}
                allowClear
              >
                {dataSupplier.map((a) => (
                  <Option key={a.id} value={a.kd_supplier}>
                    {a.nm_supplier}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label="Kode Obat"
              name="kd_brg"
              wrapperCol={{ span: 10 }}
              rules={[{ required: true, message: "Masukkan kode obat !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Nama Obat"
              name="nm_brg"
              rules={[{ required: true, message: "Masukkan nama obat !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Satuan"
              name="satuan"
              wrapperCol={{ span: 10 }}
              rules={[{ required: true, message: "Masukkan satuan obat !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Harga Jual"
              name="hrg_jual"
              // rules={[{ required: true, message: "Masukkan harga obat !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Keterangan"
              name="ket"
              // rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 20 }}>
              <Space>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isSave}>
                  Update
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
        {/* END MODAL UPDATE*/}
      </div>
    );
  }
};

export default GridObat;
