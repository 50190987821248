import { DatePicker, Form, Input, Select, Button, Space, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Moment from "moment";
import { useReactToPrint } from "react-to-print";
import ComponentToPrint from "../ComponentToPrint";
import axios from "axios";

const { Option } = Select;

const dateFormat = "DD/MM/YYYY";

const FormAntrian = (props) => {
  const [form] = Form.useForm();

  const baseUrl = props.baseUrl; //"http://192.168.1.71:8000/api";

  const userid = localStorage.getItem("id");

  const [loading, setloading] = useState(false);
  const [noantrian, setNoantrian] = useState([]);
  const [cetak, setCetak] = useState([]);
  const [tgldaftar, setTgldaftar] = useState({
    tahun: Moment(new Date()).format("YYYY"),
    bulan: Moment(new Date()).format("MM"),
    tanggal: Moment(new Date()).format("DD"),
    currentDate: Moment(new Date()).format("DD-MM-YYYY hh:mm:ss"),
  });

  const componentRef = useRef();
  const tick = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    // pageStyle: () => pageStyle,
  });

  const genNoAntrian = (thn, bln, tgl, poli) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    axios
      // .get(baseUrl + `/daftar/gennoantri/${thn}/${bln}/${tgl}/${poli}`)
      .get(baseUrl + `/daftar/gennoantri/${thn}/${bln}/${tgl}/${poli}`)
      .then((res) => {
        // console.log(res);
        if (res.status === 200) {
          form.setFieldsValue({ no_antrian: res.data.no_antrian });
          setNoantrian(res.data.no_antrian);
        } else {
          alert("XXX");
        }
      })
      .catch((err) => {
        alert(err);
      });
  };

  const changeDate = (v) => {
    setTgldaftar({
      tahun: Moment(v).format("YYYY"),
      bulan: Moment(v).format("MM"),
      tanggal: Moment(v).format("DD"),
      currentDate: Moment(v).format("DD-MM-YYYY hh:mm:ss"),
    });
  };

  const onFinish = (values) => {
    ///Generate nomor antrian
    genNoAntrian(
      tgldaftar.tahun,
      tgldaftar.bulan,
      tgldaftar.tanggal,
      props.fromRegisterPasien_poli
    );

    /// simpan data ke database
    var data = {
      tgl_daftar: Moment(values.tgl_daftar).format("YYYY-MM-DD"),
      no_kartu_pasien: values.no_kartu,
      jns_pasien: values.jns_pasien,
      no_kartu_kes: values.no_kartu_kes,
      user_id: userid,
      no_antrian: noantrian,
    };

    axios
      .post(baseUrl + "/daftar", data)
      .then((res) => {
        // console.log(res);
        if (res.status === 201) {
          message.success("Pasien berhasil didaftar");

          // ///cetak nomor antrian
          // axios
          //   .get(
          //     baseUrl +
          //       `/daftar/cetaknoantrian/${noantrian}/${props.fromRegisterPasien_poli}`
          //   )
          //   .then((res) => {
          //     console.log(res.data);
          //     setCetak(res.data[0]);
          //   })
          //   .catch((err) => {
          //     alert("Error");
          //   });

          // handlePrint();

          ///back to pasien lama
          props.onKeyChange("3"); //TAB 3 yang aktif
        } else {
          message.warning(
            "Gagal melakukan pendaftaran, coba cetak nomor antrian sekali lagi"
          );
        }
      })
      .catch((err) => {
        console.log(err);
        message.warning("Gagal melakukan pendaftaran, silahkan coba lagi");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    onFill();

    ///Generate nomor antrian
    genNoAntrian(
      tgldaftar.tahun,
      tgldaftar.bulan,
      tgldaftar.tanggal,
      props.fromRegisterPasien_poli
    );
  });

  const onFill = () => {
    form.setFieldsValue({
      no_kartu: props.fromRegisterPasien_noKartu,
      nm_pasien: props.fromRegisterPasien_nmPasien,
      alamat: props.fromRegisterPasien_alamat,
      nm_poli: props.fromRegisterPasien_poli,
      jns_pasien: "Umum",
    });
  };

  const pageStyle = `
  @page {
    size: 10mm 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  return (
    <div>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 7 }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        initialValues={{ tgl_daftar: Moment(new Date(), dateFormat) }}
      >
        <Form.Item label="No. Kartu" name="no_kartu">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Nama Pasien" name="nm_pasien">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Alamat" name="alamat">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Poli" name="nm_poli">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Tanggal Daftar" name="tgl_daftar">
          <DatePicker onChange={changeDate} format={dateFormat} disabled />
        </Form.Item>

        <Form.Item label="No. Antrian" name="no_antrian">
          <Input disabled />
        </Form.Item>

        <Form.Item
          name="jns_pasien"
          label="Tipe Pasien"
          rules={[{ required: true }]}
        >
          <Select placeholder="Pilih Tipe Pasien" allowClear>
            <Option value="Umum">Umum</Option>
            <Option value="Bpjs">Bpjs</Option>
            <Option value="Lain-lain">Lain-lain</Option>
          </Select>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.jns_pasien !== currentValues.jns_pasien
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("jns_pasien") !== "Umum" ? (
              <Form.Item label="No. Kartu Kesehatan/BPJS" name="no_kartu_kes">
                <Input />
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 3, span: 7 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Cetak Nomor Antrian
            </Button>
          </Space>
        </Form.Item>
      </Form>

      <div
        style={{ display: "none" }} // This make ComponentToPrint show   only while printing
      >
        <ComponentToPrint
          ref={componentRef}
          noAntri={noantrian.toString().substring(6)}
          waktu={tgldaftar.currentDate}
          poli={props.fromRegisterPasien_poli}
        />
      </div>
    </div>
  );
};

export default FormAntrian;
