import React, { useEffect, useState } from "react";
import "./stylerekammata.css";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  CommandColumn,
  Grid,
} from "@syncfusion/ej2-react-grids";
import {
  postRekamMata,
  putPoliMata,
  destroy,
} from "../../../config/api/apiServices";
import { Button, Col, Form, Input, Modal, Row, Space, message } from "antd";
import {
  ExclamationCircleOutlined,
  FileSearchOutlined,
} from "@ant-design/icons";
import GridPenyakit from "../../GridPenyakit/index.tsx";

const { TextArea } = Input;
const { confirm } = Modal;

const GridRekamMata = (props) => {
  const [form] = Form.useForm();
  const baseUrl = props.baseUrl;

  //api services
  const [data, setData] = useState();
  const [isModalVisible, setisModalVisible] = useState(false);
  const [getNoAntrian, setNoAntrian] = useState();
  const [getNoKartuPasien, setNoKartuPasien] = useState();
  const [modalDiagnosa, setModalDiagnosa] = useState(false);
  const [modalTheraphy, setModalTheraphy] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState([]);

  useEffect(() => {
    let isMounted = true;
    postRekamMata(props.noKartuPasien).then((data) => {
      if (isMounted) setData(data);
    });
    return () => {
      isMounted = false;
    };
  }, [data]);

  let grid: Grid | null;
  const commandClick = (args) => {
    if (grid) {
      if (args.commandColumn.buttonOption.iconCss === "e-icons e-edit") {
        setNoAntrian(args.rowData.no_antrian);
        setNoKartuPasien(args.rowData.no_kartu_pasien);
        setisModalVisible(true);
        form.setFieldsValue({
          id: args.rowData.id,
          penyakit: args.rowData.penyakit,
          diagnosa: args.rowData.diagnosa,
          theraphy: args.rowData.theraphy,
        });
      }
      if (args.commandColumn.buttonOption.iconCss === "e-icons e-menu") {
        // console.log("lihat ketikan terakhir pada form");

        /// lihat ketikan terakhir pada form, tujuannya jika tidak tersimpan
        /// maka ketikan data tidak kosong dari kotak input pada modal
        setNoAntrian(args.rowData.no_antrian);
        setNoKartuPasien(args.rowData.no_kartu_pasien);
        setisModalVisible(true);
        form.setFieldsValue({
          id: args.rowData.id,
        });
      }
      if (args.commandColumn.buttonOption.iconCss === "e-icons e-delete") {
        // console.log("delete");
        showConfirm(args.rowData.id);
      }
    }
  };

  //tombol pada grid
  const commands = [
    {
      // type: "Edit",
      buttonOption: {
        iconCss: "e-icons e-edit",
        cssClass: "e-flat",
      },
    },
    {
      // type: "Edit",
      buttonOption: {
        iconCss: "e-icons e-menu",
        cssClass: "e-flat",
      },
    },
    {
      // type: "Delete",
      buttonOption: {
        iconCss: "e-icons e-delete",
        cssClass: "e-flat",
      },
    },
  ];

  // scrip modal
  const handleCancel = () => {
    setisModalVisible(false);
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    // console.log("No Antrian  :", getNoAntrian);

    putPoliMata(
      values.id,
      getNoAntrian,
      getNoKartuPasien,
      values.penyakit,
      values.diagnosa,
      values.theraphy,
      "",
      "1"
    );

    //  ///form di clear
    //  form.setFieldsValue({ penyakit: "", diagnosa: "", theraphy: "" });

    setisModalVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showConfirm = (id) => {
    confirm({
      title: "Hapus Rekam Medis",
      icon: <ExclamationCircleOutlined />,
      content: `Yakin data akan dihapus?`,

      onOk() {
        destroy(id);
        message.success("Rekam Medis berhasil dihapus");
      },

      // onCancel() {
      //   message.error("Click on No");
      // },
    });
  };

  const handleCariDiagnosa = () => {
    setModalDiagnosa(true);
  };

  const handleCariTheraphy = () => {
    setModalTheraphy(true);
  };

  const getSelectedRecord = (a) => {
    // console.log(a);
    setSelectedRecord(a);
  };

  const handleOkCariDiagnosa = () => {
    setModalDiagnosa(false);
    form.setFieldsValue({
      diagnosa: selectedRecord.diagnosa,
    });
  };

  const handleOkCariTheraphy = () => {
    setModalTheraphy(false);
    form.setFieldsValue({
      theraphy: selectedRecord.theraphy,
    });
  };

  return (
    <div>
      <Row>
        <Col span={18} push={6}>
          : {props.noAntrian}
        </Col>
        <Col span={6} pull={18}>
          No. Antrian
        </Col>
      </Row>
      <Row>
        <Col span={18} push={6}>
          : {props.noKartuPasien}
        </Col>
        <Col span={6} pull={18}>
          No. Kartu
        </Col>
      </Row>
      <Row>
        <Col span={18} push={6}>
          : {props.nmPasien}
        </Col>
        <Col span={4} pull={18}>
          Nama Pasien
        </Col>
      </Row>
      <Row>
        <Col span={18} push={6}>
          : {props.tglLahir}
        </Col>
        <Col span={4} pull={18}>
          Tgl. Lahir
        </Col>
      </Row>
      <Row className="rowPadding">
        <Col span={18} push={6}>
          : {props.alamat}
        </Col>
        <Col span={4} pull={18}>
          Alamat
        </Col>
      </Row>

      <GridComponent
        dataSource={data}
        height="200px"
        ref={(g) => (grid = g)}
        commandClick={commandClick}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="tgl_daftar"
            headerText="TGL. PERIKSA"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="penyakit"
            headerText="JENIS PENYAKIT"
            // textAlign="center"
            // width="300"
          />

          <ColumnDirective
            field="diagnosa"
            headerText="DIAGNOSA"
            // textAlign="center"
            // width="300"
          />

          <ColumnDirective
            field="theraphy"
            headerText="THERAPHY"
            // textAlign="center"
            // width="300"
          />

          <ColumnDirective
            // field="id"
            headerText="Aksi"
            // textAlign="center"
            width="200"
            commands={commands}
          />
        </ColumnsDirective>
        <Inject services={[CommandColumn]} />
      </GridComponent>

      {/* show modal */}
      <Modal
        title="Input/Edit Rekam Medis"
        visible={isModalVisible}
        onOk={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        maskClosable={false}
      >
        {/* form input */}
        <Form
          name="control-hooks"
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="ID"
            name="id"
            hidden={true}
            //   rules={[{ required: true, message: "Masukkan nik pasien!" }]}
          >
            <Input />
          </Form.Item>

          {/* penyakit */}
          <Form.Item
            label="Jns Penyakit"
            name="penyakit"
            //   rules={[{ required: true, message: "Masukkan nik pasien!" }]}
          >
            <TextArea rows={4} />
          </Form.Item>

          {/* diagnosa */}
          <Form.Item
            label="Diagnosa"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="diagnosa"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(90% - 8px)",
              }}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(10% - 8px)",
                margin: "0 8px",
              }}
            >
              <Button type="dashed" size="small" onClick={handleCariDiagnosa}>
                <FileSearchOutlined />
              </Button>
            </Form.Item>
          </Form.Item>

          {/* theraphy */}
          <Form.Item
            label="Theraphy"
            style={{
              marginBottom: 0,
            }}
          >
            <Form.Item
              name="theraphy"
              rules={[
                {
                  required: true,
                },
              ]}
              style={{
                display: "inline-block",
                width: "calc(90% - 8px)",
              }}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item
              style={{
                display: "inline-block",
                width: "calc(10% - 8px)",
                margin: "0 8px",
              }}
            >
              <Button type="dashed" size="small" onClick={handleCariTheraphy}>
                <FileSearchOutlined />
              </Button>
            </Form.Item>
          </Form.Item>

          {/* <Form.Item
            label="Jns Penyakit"
            name="penyakit"
            //   rules={[{ required: true, message: "Masukkan nik pasien!" }]}
          >
            <TextArea rows={4} />
          </Form.Item> */}

          {/* <Form.Item label="Diagnosa" name="diagnosa">
            <TextArea rows={4} />
          </Form.Item>

          <Form.Item label="Theraphy" name="theraphy">
            <TextArea rows={4} />
          </Form.Item> */}

          <Form.Item wrapperCol={{ offset: 5, span: 18 }}>
            <Space>
              <Button onClick={handleCancel}>Cancel</Button>
              <Button type="primary" htmlType="submit">
                Simpan
              </Button>
            </Space>
          </Form.Item>
        </Form>
        {/* end form input */}
      </Modal>
      {/* end show modal */}

      {/* show modal diagnosa */}
      <Modal
        title="Cari diagnosa"
        // width={700}
        visible={modalDiagnosa}
        onOk={handleOkCariDiagnosa}
        onCancel={() => setModalDiagnosa(false)}
        // footer={null}
        closable={false}
        // maskClosable={false}
      >
        <GridPenyakit
          baseUrl={baseUrl}
          penyakit={form.getFieldValue("penyakit")}
          namafield="diagnosa"
          getSelectedRecord={getSelectedRecord}
        />
      </Modal>
      {/* end show modal diagnosa */}

      {/* show modal theraphy */}
      <Modal
        title="Cari theraphy"
        // width={700}
        visible={modalTheraphy}
        onOk={handleOkCariTheraphy}
        onCancel={() => setModalTheraphy(false)}
        // footer={null}
        closable={false}
        // maskClosable={false}
      >
        <GridPenyakit
          baseUrl={baseUrl}
          penyakit={form.getFieldValue("penyakit")}
          namafield="theraphy"
          getSelectedRecord={getSelectedRecord}
        />
      </Modal>
      {/* end show modal theraphy */}
    </div>
  );
};

export default GridRekamMata;
