import React, { useState } from "react";
import { Card, DatePicker, Space, Form } from "antd";
import GridBrgIn from "../../../../component/GridBrgIn/index.tsx";
import moment from "moment";

const { RangePicker } = DatePicker;

const BrgIn = (props) => {
  const [form] = Form.useForm();
  const [dates, setDates] = useState([]);
  const baseUrl = props.baseUrl;

  const onChange = (values) => {
    // const date1 = moment(value[0]).format('DD-MM-YYYY');
    // const date2 = moment(value[1]).format('DD-MM-YYYY');
    // console.log(date1, date2);
    setDates(
      values.map((item) => {
        return moment(item).format("YYYY-MM-DD");
      })
    );
  };

  return (
    <div>
      <Space direction="Vertical" align="center">
        <Form
          form={form}
          name="basic"
          // initialValues={{
          //   picker: [dates[0], dates[1]],
          // }}
        >
          <Form.Item name="picker" label="Tentukan Tanggal">
            <RangePicker format="DD-MM-YYYY" onChange={onChange} />
          </Form.Item>
        </Form>
      </Space>
      <Card size="small" title="Barang Masuk">
        <GridBrgIn baseUrl={baseUrl} dates={dates} />
      </Card>
    </div>
  );
};

export default BrgIn;
