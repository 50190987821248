import React, { useEffect, useState } from "react";
import "./stylerekamforstaff.css";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  CommandColumn,
  Grid,
} from "@syncfusion/ej2-react-grids";
import { postRekamMata, putPoliMata } from "../../../config/api/apiServices";
import { Button, Col, Form, Input, Modal, Row, Space } from "antd";

const { TextArea } = Input;

const GridRekamForStaff = (props) => {
  const [form] = Form.useForm();

  //api services
  const [data, setData] = useState();
  const [isModalVisible, setisModalVisible] = useState(false);
  const [getNoAntrian, setgetNoAntrian] = useState();

  useEffect(() => {
    let isMounted = true;
    postRekamMata(props.noKartuPasien).then((data) => {
      if (isMounted) setData(data);
    });
    return () => {
      isMounted = false;
    };
  }, [data]);

  //tombol pada grid
  const commands = [
    {
      // type: "Edit",
      buttonOption: {
        iconCss: " e-icons e-eye",
        cssClass: "e-flat",
        click: () => {
          // navigate("/kontainer/"+a);
          // console.log(data.nm_kapal);
          // console.log("edit click");
          setisModalVisible(true);
          form.setFieldsValue({
            penyakit: data[0].penyakit,
            diagnosa: data[0].diagnosa,
            theraphy: data[0].theraphy,
          });
        },
      },
    },
    // {
    //   // type: "Delete",
    //   buttonOption: {
    //     iconCss: " e-icons e-delete",
    //     cssClass: "e-flat",
    //     click: () => {
    //       // navigate("/kontainer/"+a);
    //       // console.log(data.nm_kapal);
    //       console.log("delete click");
    //     },
    //   },
    // },
  ];

  // scrip modal
  const handleCancel = () => {
    setisModalVisible(false);
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    putPoliMata(
      getNoAntrian,
      values.penyakit,
      values.diagnosa,
      values.theraphy,
      "",
      "1"
    );

    ///form di clear
    form.setFieldsValue({ penyakit: "", diagnosa: "", theraphy: "" });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  let grid: Grid | null;
  const rowSelected = () => {
    if (grid) {
      console.log(grid.getSelectedRecords()[0].no_antrian);
      setgetNoAntrian(grid.getSelectedRecords()[0].no_antrian);
      form.setFieldsValue({
        penyakit: grid.getSelectedRecords()[0].penyakit,
        diagnosa: grid.getSelectedRecords()[0].diagnosa,
        theraphy: grid.getSelectedRecords()[0].theraphy,
      });
    }
  };

  return (
    <div>
      <Row>
        <Col span={18} push={6}>
          : {props.noKartuPasien} / POLI {props.poli}
        </Col>
        <Col span={6} pull={18}>
          No. Kartu
        </Col>
      </Row>
      <Row>
        <Col span={18} push={6}>
          : {props.nmPasien}
        </Col>
        <Col span={4} pull={18}>
          Nama Pasien
        </Col>
      </Row>
      <Row>
        <Col span={18} push={6}>
          : {props.tglLahir}
        </Col>
        <Col span={4} pull={18}>
          Tgl. Lahir
        </Col>
      </Row>
      <Row className="rowPadding">
        <Col span={18} push={6}>
          : {props.alamat}
        </Col>
        <Col span={4} pull={18}>
          Alamat
        </Col>
      </Row>

      <GridComponent
        dataSource={data}
        height="200px"
        ref={(g) => (grid = g)}
        rowSelected={rowSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="tgl_daftar"
            headerText="TGL. PERIKSA"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="penyakit"
            headerText="JENIS PENYAKIT"
          // textAlign="center"
          // width="300"
          />

          <ColumnDirective
            field="diagnosa"
            headerText="DIAGNOSA"
          // textAlign="center"
          // width="300"
          />

          <ColumnDirective
            field="theraphy"
            headerText="THERAPHY"
          // textAlign="center"
          // width="300"
          />
          <ColumnDirective
            // field="id"
            headerText="View"
            // textAlign="center"
            width="100"
            commands={commands}
          />
        </ColumnsDirective>
        <Inject services={[CommandColumn]} />
      </GridComponent>

      {/* show modal */}
      <Modal
        title="Input/Edit Rekam Medis"
        visible={isModalVisible}
        onOk={false}
        onCancel={handleCancel}
        footer={null}
        closable={false}
      >
        {/* form input */}
        <Form
          name="control-hooks"
          form={form}
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 18 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Jns Penyakit"
            name="penyakit"
          //   rules={[{ required: true, message: "Masukkan nik pasien!" }]}
          >
            <TextArea rows={4} readOnly />
          </Form.Item>

          <Form.Item label="Diagnosa" name="diagnosa">
            <TextArea rows={4} readOnly />
          </Form.Item>

          <Form.Item label="Theraphy" name="theraphy">
            <TextArea rows={4} readOnly />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 5, span: 18 }}>
            <Space>
              <Button type="primary" onClick={handleCancel}>Cancel</Button>
              {/* <Button type="primary" htmlType="submit">
                Simpan
              </Button> */}
            </Space>
          </Form.Item>
        </Form>
        {/* end form input */}
      </Modal>
      {/* end show modal */}
    </div>
  );
};

export default GridRekamForStaff;
