import React, { useState } from "react";
import { Card } from "antd";
import GridObat from "../../../component/GridObat/index.tsx";

const Obat = (props) => {
  const baseUrl = props.baseUrl;

  const [selectedObat, setSelectedObat] = useState([]);

  const getSelectedObat = (a) => {
    // console.log(a);
    setSelectedObat(a);
  };

  return (
    <div>
      <Card size="small" title="Master Obat">
        <GridObat baseUrl={baseUrl} getSelectedObat={getSelectedObat} />
      </Card>
    </div>
  );
};

export default Obat;
