import React, { useEffect, useState } from "react";
import "./styleGridAntrian.css";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Toolbar,
  Search,
  Inject,
  CommandColumn,
  Grid,
} from "@syncfusion/ej2-react-grids";
import {
  getPendaftaran,
  putUpdateStatus,
  deleteRekamMata,
} from "../../../config/api/apiServices";
import { message, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Moment from "moment";

const { confirm } = Modal;

const GridAntrian = (props) => {
  const toolbarku = ["Search"];
  const [tgldaftar, setTgldaftar] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );

  const [row, setRow] = useState({
    noantrianpasien: "",
    noantri: "",
    nokartupasien: "",
  });

  //api services
  const [data, setData] = useState();
  useEffect(() => {
    let isMounted = true;
    getPendaftaran(tgldaftar, props.poli).then((data) => {
      if (isMounted) setData(data);
    });
    return () => {
      isMounted = false;
    };
  }, [data]);

  const showConfirm = (noantrianpasien, noantri, nokartupasien) => {
    confirm({
      title: "Hapus Antrian",
      icon: <ExclamationCircleOutlined />,
      content: `Yakin antrian ${noantri} akan di batalkan?`,

      onOk() {
        putUpdateStatus(noantrianpasien, nokartupasien, "cancel", "1");
        deleteRekamMata(noantrianpasien, nokartupasien);
        message.success("Pasien berhasil di cancel");
      },

      // onCancel() {
      //   message.error("Click on No");
      // },
    });
  };

  let grid: Grid | null;
  const commandClick = (args) => {
    if (grid) {
      if (args.commandColumn.buttonOption.content === "Ada") {
        putUpdateStatus(
          args.rowData.no_antrian,
          args.rowData.no_kartu_pasien,
          "checkup",
          "1"
        );
      }
      if (args.commandColumn.buttonOption.content === "Cancel") {
        console.log("Tombol Cancel di klik");
        showConfirm(
          args.rowData.no_antrian,
          args.rowData.noAntri,
          args.rowData.no_kartu_pasien
        );
      }
    }
  };

  const commands = [
    {
      // type: "Edit",
      buttonOption: {
        iconCss: " e-icons e-add",
        cssClass: "e-flat",
        content: "Ada",
        // click: (e) => {}
      },
    },
    {
      // type: "Edit",
      buttonOption: {
        iconCss: " e-icons e-delete",
        cssClass: "e-flat",
        content: "Cancel",
        // click: (e) => {
        //     console.log('cancel');
        //     // showConfirm(row.noantrianpasien, row.noantri, row.nokartupasien);

        // },
      },
    },
  ];

  const rowDataBound = (e) => {
    // console.log(e);

    if (e.data.status_aktivitas === "call") {
      e.row.classList.add("below-30");
    }
    if (e.data.status_aktivitas === "checkup") {
      e.row.classList.add("below-40");
    }
  };

  return (
    <div>
      <GridComponent
        dataSource={data}
        height="200px"
        ref={(g) => (grid = g)}
        toolbar={toolbarku}
        commandClick={commandClick}
        rowDataBound={rowDataBound}
        // rowSelected={rowSelected}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="tgl_daftar"
            headerText="Tanggal"
            // textAlign="center"
            width="120"
          />
          <ColumnDirective
            field="noAntri"
            headerText="NO. ANTRI"
            // textAlign="center"
            width="100"
          />

          <ColumnDirective
            field="no_kartu_pasien"
            headerText="NO. KARTU"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="nm_pasien"
            headerText="NAMA PASIEN"
            // textAlign="center"
            width="200"
          />

          {/* <ColumnDirective
            field="nm_poli"
            headerText="POLI"
            // textAlign="center"
            width="50"
          /> */}

          <ColumnDirective
            field="status_aktivitas"
            headerText="STATUS"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            // field="id"
            headerText="Confirmasi"
            // textAlign="center"
            width="200"
            commands={commands}
          />
        </ColumnsDirective>
        <Inject services={[Search, Toolbar, CommandColumn]} />
      </GridComponent>
    </div>
  );
};

export default GridAntrian;
