import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  CommandColumn,
  Grid,
  Page,
  Search,
} from "@syncfusion/ej2-react-grids";
import axios from "axios";

const GridPenyakit = (props) => {
  let grid: Grid | null;
  const baseUrl = props.baseUrl;

  const [data, setData] = useState([]);

  useEffect(() => {
    let isMounted = true;

    // var data = {
    //   penyakit: props.penyakit,
    //   value: props.namafield,
    // };
    axios
      .get(baseUrl + `/polimata/get${props.namafield}`)
      .then((res) => {
        if (isMounted) {
          // console.log(res.data);
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, [data]);

  const rowSelected = () => {
    if (grid) {
      let record = grid.getSelectedRecords()[0];

      ///kirim datanya ke grid rekam mata
      props.getSelectedRecord(record);
    }
  };

  const toolbarku = ["Search"];

  return (
    <div>
      <GridComponent
        dataSource={data}
        height="200px"
        ref={(g) => (grid = g)}
        allowPaging={true}
        pageSettings={{ pageSize: 5, pageCount: 5, pageSizes: true }}
        rowSelected={rowSelected}
        toolbar={toolbarku}
      >
        <ColumnsDirective>
          <ColumnDirective
            field={props.namafield}
            headerText={props.namafield}
            // textAlign="center"
            // width="300"
          />
        </ColumnsDirective>
        <Inject services={[Page, Search]} />
      </GridComponent>
    </div>
  );
};

export default GridPenyakit;
