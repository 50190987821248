import React, { useEffect, useState } from "react";
import { TeamOutlined } from "@ant-design/icons";
import { Card, Col, Row, Statistic, Typography } from "antd";
import "./styleHome.css";
import axios from "axios";

const { Title } = Typography;

const Home = (props) => {
  const [polimata, setPoliMata] = useState();
  const [poligigi, setPoliGigi] = useState();

  const baseUrl = props.baseUrl;//"http://192.168.1.71:8000/api";

  //utk mata
  useEffect(() => {
    var data = {
      poli: "MATA",
    };
    axios.post(baseUrl + "/daftar/countpasien", data).then((res) => {
      setPoliMata(res.data[0].count);
    });
  }, [polimata]);

  //utk gigi
  useEffect(() => {
    var data = {
      poli: "GIGI",
    };
    axios.post(baseUrl + "/daftar/countpasien", data).then((res) => {
      setPoliGigi(res.data[0].count);
    });
  }, [poligigi]);

  return (
    <div className="site-statistic-demo-card">
      <Row gutter={16}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Antrian Hari Ini"
              value={polimata}
              precision={0}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={<TeamOutlined />}
              suffix="pasien"
            />
            <Title level={5} style={{ marginTop: "10px" }}>
              POLI MATA
            </Title>
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Antrian Hari Ini"
              value={poligigi}
              precision={0}
              valueStyle={{
                color: "#3f8600",
              }}
              prefix={<TeamOutlined />}
              suffix="pasien"
            />
            <Title level={5} style={{ marginTop: "10px" }}>
              POLI GIGI
            </Title>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
