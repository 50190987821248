import React, { useState } from "react";
import { Typography, Tabs, Col, Row } from "antd";
import FormPasien from "../../../component/Pasien/FormPasien";
import FormAntrian from "../../../component/Pasien/FormAntrian";
import GridPasien from "../../../component/Pasien/GridPasien/index.tsx";
import GridAntrian from "../../../component/Pasien/GridAntrian/index.tsx";
import ListPoli from "../../../component/ListPoli";
import GridRekamForStaff from "../../../component/RekamMedis/GridRekamForStaff";
import GridHasilCheckUp from "../../../component/RekamMedis/GridHasilCheckUp/index.tsx";

// import { Viewer } from "@grapecity/activereports-react";
// import "@grapecity/activereports/pdfexport";
// import "@grapecity/activereports/htmlexport";
// import "@grapecity/activereports/tabulardataexport";
// import "@grapecity/activereports-localization";
// import { FontStore } from "@grapecity/activereports/core";

const { TabPane } = Tabs;
const { Title } = Typography;

const style = {
  // background: '#0092ff',
  padding: "8px 0",
};

// const printLangsung = () => {
//   //One Touch Printing
//   var ARJS = GC.ActiveReports.Core;
//   var pageReport = new ARJS.PageReport();
//   pageReport
//     .load("antrian.rdlx-json")
//     .then(() => pageReport.run())
//     .then((pageDocument) => pageDocument.print());
// };

// const exportsSettings = {
//   pdf: {
//     title: "ActiveReportsJS Sample",
//     author: "GrapeCity",
//     subject: "Web Reporting",
//     keywords: "reporting, sample",
//     userPassword: "pwd",
//     ownerPassword: "ownerPwd",
//     printing: "none",
//     copying: false,
//     modifying: false,
//     annotating: false,
//     contentAccessibility: false,
//     documentAssembly: false,
//     pdfVersion: "1.7",
//     autoPrint: true,
//     filename: "ActiveReportsJS-Sample.pdf",
//   },
//   html: {
//     title: "ActiveReportsJS Sample",
//     filename: "ActiveReportsJS-Sample.html",
//     autoPrint: true,
//     multiPage: true,
//     embedImages: "external",
//     outputType: "html",
//   },
// };

const RegistrasiPasien = (props) => {

  const baseUrl = props.baseUrl;
  const [activeKey, setActiveKey] = useState("1");

  const [noKartu, setnoKartu] = useState();
  const [nmPasien, setnmPasien] = useState();
  const [alamat, setAlamat] = useState();
  const [poli, setPoli] = useState(localStorage.getItem("poli"));
  const [tglLahir, setTglLahir] = useState();

  const onKeyChange = (key) => {
    setActiveKey(key);
  };

  const ambilNoKartuPasien = (a) => {
    // console.log(a);
    setnoKartu(a);
  };

  const ambilNamaPasien = (a) => {
    // console.log(a);
    setnmPasien(a);
  };

  const ambilAlamat = (a) => {
    // console.log(a);
    setAlamat(a);
  };
  const ambilPoli = (a) => {
    // console.log(a);
    setPoli(a);
  };
  const ambilTglLahir = (a) => {
    // console.log(a);
    setTglLahir(a);
  };

  return (
    <div>
      <Title level={4}>Registrasi Pasien</Title>
      {/* <Button type="primary">{printLangsung}</Button> */}

      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}>
        <TabPane tab="PILIH POLI" key="1">
          <div>
            <ListPoli setPoli={ambilPoli} onKeyChange={onKeyChange} />
          </div>
        </TabPane>

        <TabPane tab="PASIEN BARU" key="2" disabled>
          <FormPasien baseUrl={baseUrl}
            onKeyChange={onKeyChange}
            setNoKartuPasien={ambilNoKartuPasien}
            setAlamat={ambilAlamat}
            setNamaPasien={ambilNamaPasien}
            setPoli={ambilPoli}
            poli={poli}
          />
        </TabPane>
        <TabPane tab="PASIEN LAMA" key="3">
          <Title level={5}>Data Pasien POLI {poli}</Title>
          <GridPasien
            onKeyChange={onKeyChange}
            setNoKartuPasien={ambilNoKartuPasien}
            setNamaPasien={ambilNamaPasien}
            setAlamat={ambilAlamat}
            setPoli={ambilPoli}
            setTglLahir={ambilTglLahir}
            poli={poli}
          />
        </TabPane>
        <TabPane tab="AMBIL ANTRIAN" key="4" disabled>
          <FormAntrian baseUrl={baseUrl}
            onKeyChange={onKeyChange}
            fromRegisterPasien_noKartu={noKartu}
            fromRegisterPasien_nmPasien={nmPasien}
            fromRegisterPasien_alamat={alamat}
            fromRegisterPasien_poli={poli}
          />

          {/* <Viewer
            report={{ Uri: "antrian.rdlx-json" }}
            exportsSettings={exportsSettings}
          /> */}
        </TabPane>
        <TabPane tab="DAFTAR ANTRIAN PASIEN" key="5">
          {/* <Row gutter={16}>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <Title level={5}>Antrian Pasien POLI MATA</Title>
                <GridAntrian poli="MATA" />
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              <div style={style}>
                <Title level={5}>Antrian Pasien POLI GIGI</Title>
                <GridAntrian poli="GIGI" />
              </div>
            </Col>
          </Row> */}

          <div style={style}>
            <Title level={5}>Antrian Pasien POLI MATA</Title>
            <GridAntrian poli="MATA" />
          </div>
          <div style={style}>
            <Title level={5}>Antrian Pasien POLI GIGI</Title>
            <GridAntrian poli="GIGI" />
          </div>
        </TabPane>

        <TabPane tab="REKAM MEDIS" key="6" disabled>
          <GridRekamForStaff baseUrl={baseUrl}
            onKeyChange={onKeyChange}
            noKartuPasien={noKartu}
            nmPasien={nmPasien}
            tglLahir={tglLahir}
            alamat={alamat}
            poli={poli}
          />
        </TabPane>

        <TabPane tab="HASIL CHECKUP" key="7">
          <GridHasilCheckUp baseUrl={baseUrl}
            onKeyChange={onKeyChange}
          // setNoAntrian={ambilNoAntrian}
          // setNoKartuPasien={ambilNoKartuPasien}
          // setNamaPasien={ambilNamaPasien}
          // setTglLahir={ambilTglLahir}
          // setAlamat={ambilAlamat}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

// FontStore.registerFonts("/activereportsjs/demos/resource/fontsConfig.json");

export default RegistrasiPasien;
