import React, { useEffect, useState } from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Toolbar,
    Search,
    Inject,
    CommandColumn,
    Grid,
} from "@syncfusion/ej2-react-grids";
import { getPasien } from "../../../config/api/apiServices";
import axios from "axios";
import Moment from "moment";
import { Button, Form, Input, Modal, Space } from "antd";

const { TextArea } = Input;

const GridHasilCheckUp = (props) => {
    const [form] = Form.useForm();
    const poli = localStorage.getItem("poli");

    const baseUrl = props.baseUrl;

    let currentDate = Moment(new Date()).format("YYYY-MM-DD");

    const [data, setData] = useState();
    const [isModalVisible, setisModalVisible] = useState(false);

    let grid: Grid | null;

    const toolbarku = [
        "Search",
    ];

    //api services
    useEffect(() => {
        let isMounted = true;
        var input = {
            tgl_daftar: currentDate,
            nm_poli: poli,
        }
        axios.post(baseUrl + '/hasilcheckup', input)
            .then((res) => {
                if (isMounted) setData(res.data);
            }).catch(err => {
                console.log(err);
            })

        return () => {
            isMounted = false;
        };
    }, [data]);

    const commands = [
        {
            // type: "Edit",
            buttonOption: {
                iconCss: " e-icons e-eye",
                cssClass: "e-flat",
                // content: "Rekam Medis",
                backgroundColor: "red",
                // click: (args) => {
                //   // console.log(args);
                //   props.onKeyChange("2"); //TAB 4 yang aktif
                // },
            },
        },
    ];

    const commandClick = (args) => {
        if (grid) {
            /// console.log(args.rowData.tgl_lahir);
            form.setFieldsValue({
                penyakit: args.rowData.penyakit,
                diagnosa: args.rowData.diagnosa,
                theraphy: args.rowData.theraphy,
            });
            setisModalVisible(true);
        }
    };

    const handleCancel = () => {
        setisModalVisible(false);
    }

    return (
        <div>
            <GridComponent
                ref={(g) => (grid = g)}
                dataSource={data}
                height="200px"
                commandClick={commandClick}
                toolbar={toolbarku}
            >
                <ColumnsDirective>
                    <ColumnDirective
                        field="tgl_daftar"
                        headerText="TANGGAL"
                        // textAlign="center"
                        width="120"
                    />

                    <ColumnDirective
                        field="no_antrian"
                        headerText="NO. ANTRIAN"
                        // textAlign="center"
                        width="120"
                    />

                    <ColumnDirective
                        field="no_kartu_pasien"
                        headerText="NO. KARTU"
                        // textAlign="center"
                        width="120"
                    />
                    <ColumnDirective
                        field="penyakit"
                        headerText="PENYAKIT"
                        // textAlign="center"
                        width="120"
                    />
                    <ColumnDirective
                        field="diagnosa"
                        headerText="DIAGNOSA"
                        // textAlign="center"
                        width="120"
                    />
                    <ColumnDirective
                        field="theraphy"
                        headerText="THERAPHY"
                        // textAlign="center"
                        width="120"
                    />

                    <ColumnDirective
                        // field="id"
                        headerText="VIEW"
                        textAlign="center"
                        width="200"
                        commands={commands}
                    />

                    <ColumnDirective
                        field="nik"
                        headerText="NIK"
                        // textAlign="center"
                        width="200"
                    />

                    <ColumnDirective
                        field="nm_pasien"
                        headerText="NAMA PASIEN"
                        // textAlign="center"
                        width="200"
                    />

                    <ColumnDirective
                        field="alamat"
                        headerText="ALAMAT"
                        // textAlign="center"
                        width="300"
                    />
                </ColumnsDirective>
                <Inject services={[Search, Toolbar, CommandColumn]} />
            </GridComponent>


            {/* show modal */}
            <Modal
                title="View Form"
                visible={isModalVisible}
                onOk={false}
                onCancel={handleCancel}
                footer={null}
                closable={false}
            >
                {/* form input */}
                <Form
                    name="control-hooks"
                    form={form}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={{ remember: true }}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Jns Penyakit"
                        name="penyakit"
                    //   rules={[{ required: true, message: "Masukkan nik pasien!" }]}
                    >
                        <TextArea rows={4} readOnly />
                    </Form.Item>

                    <Form.Item label="Diagnosa" name="diagnosa">
                        <TextArea rows={4} readOnly />
                    </Form.Item>

                    <Form.Item label="Theraphy" name="theraphy">
                        <TextArea rows={4} readOnly />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 5, span: 18 }}>
                        <Space>
                            <Button type="primary" onClick={handleCancel}>Cancel</Button>
                            {/* <Button type="primary" htmlType="submit">
                Simpan
              </Button> */}
                        </Space>
                    </Form.Item>
                </Form>
                {/* end form input */}
            </Modal>
            {/* end show modal */}
        </div>
    );
};

export default GridHasilCheckUp;
