import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Toolbar,
  Search,
  Inject,
  CommandColumn,
  Grid,
  Page,
} from "@syncfusion/ej2-react-grids";
import { getPasien } from "../../../config/api/apiServices";

const GridPasienForDoctor = (props) => {
  const poli = localStorage.getItem("poli");

  let grid: Grid | null;

  const toolbarku = ["Search"];

  //api services
  const [data, setData] = useState();
  useEffect(() => {
    let isMounted = true;
    getPasien(poli).then((res) => {
      if (isMounted) setData(res);
    });
    return () => {
      isMounted = false;
    };
  }, [data]);

  const commands = [
    {
      // type: "Edit",
      buttonOption: {
        // iconCss: " e-icons e-play",
        cssClass: "e-flat",
        content: "Rekam Medis",
        backgroundColor: "red",
        // click: (args) => {
        //   // console.log(args);
        //   props.onKeyChange("2"); //TAB 4 yang aktif
        // },
      },
    },
  ];

  const commandClick = (args) => {
    if (grid) {
      // console.log(args.rowData.tgl_lahir);
      props.setNoKartuPasien(args.rowData.no_kartu_pasien);
      props.setNamaPasien(args.rowData.nm_pasien);
      props.setAlamat(args.rowData.alamat);
      //   props.setPoli(args.rowData.nm_poli);
      props.setTglLahir(args.rowData.tgl_lahir);
      props.onKeyChange("2"); //TAB 3 yang aktif
    }
  };

  return (
    <div>
      <GridComponent
        ref={(g) => (grid = g)}
        dataSource={data}
        height="200px"
        allowPaging={true}
        pageSettings={{ pageSize: 10, pageCount: 5, pageSizes: true }}
        commandClick={commandClick}
        toolbar={toolbarku}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            headerText="ID"
            // textAlign="center"
            width="90"
          />

          <ColumnDirective
            field="nm_poli"
            headerText="POLI"
            // textAlign="center"
            width="100"
          />

          <ColumnDirective
            // field="id"
            headerText="Command"
            textAlign="center"
            width="200"
            commands={commands}
          />

          <ColumnDirective
            field="no_kartu_pasien"
            headerText="NO. KARTU"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="nik"
            headerText="NIK"
            // textAlign="center"
            width="200"
          />

          <ColumnDirective
            field="nm_pasien"
            headerText="NAMA PASIEN"
            // textAlign="center"
            width="300"
          />

          <ColumnDirective
            field="tgl_lahir"
            headerText="TGL. LAHIR"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="jns_kelamin"
            headerText="GENDER"
            // textAlign="center"
            width="120"
          />
          <ColumnDirective
            field="alamat"
            headerText="ALAMAT"
            // textAlign="center"
            width="300"
          />
          <ColumnDirective
            field="no_hp"
            headerText="NO. HP"
            // textAlign="center"
            width="120"
          />
        </ColumnsDirective>
        <Inject services={[Search, Toolbar, CommandColumn, Page]} />
      </GridComponent>
    </div>
  );
};

export default GridPasienForDoctor;
