import React, { useState, useEffect, useLayoutEffect } from "react";
import Home from "./container/pages/Home/index";
import "./App.css";
import { Link, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {
    Layout,
    Menu,
    Breadcrumb,
    Typography,
    Avatar,
    Drawer,
    message,
} from "antd";
import {
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    VideoCameraOutlined,
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import Loginform from "./container/Loginform/index";
import RegistrasiPasien from "./container/pages/RegistrasiPasien/index";
import MyNavigator from "./component/MyNavigator";
import PoliMata from "./container/pages/PoliMata";
import RegistrasiUser from "./container/pages/RegistrasiUser";
import axios from "axios";
import FormPasienEdit from "./component/Pasien/FormPasienEdit";
import BrgIn from "./container/pages/Apotik/BrgIn";
import Supplier from "./container/pages/Supplier";
import Obat from "./container/pages/Obat";
import BrgOut from "./container/pages/Apotik/BrgOut";

const { Header, Content, Sider } = Layout;

const App = () => {
    const [collapsed, setCollapsed] = useState(false);
    const [lebarSider, setLebarSider] = useState();
    const [visibleDrawer, setVisibleDrawer] = useState(false);

    // const baseUrl = "http://192.168.1.71:8000/api";
    const baseUrl = "https://lumen8apipasien.gg-ssp.com/api";
    // const baseUrl = "http://localhost:8000/api";

    const { Title } = Typography;

    const [islogin, setIslogin] = useState(localStorage.getItem("islogin"));

    useEffect(() => {
        const u = localStorage.getItem("islogin");
        u && JSON.parse(u) ? setIslogin(true) : setIslogin(false);
    }, []);

    const [user, setUser] = useState({ username: "", password: "" });
    const [error, setError] = useState("");
    const [nmlengkap, setNmlengkap] = useState(localStorage.getItem("name"));

    const [nokartu, setNokartu] = useState();

    const ambilKartuPasien = (e) => {
        setNokartu(e);
    };

    const Login = (details) => {
        // console.log(details.username);
        var data = {
            email: details.username,
            password: details.password,
        };

        axios
            .post(baseUrl + "/login", data)
            .then((res) => {
                // localStorage.setItem("islogin", true);

                // console.log(res.data.data.user);
                localStorage.setItem("id", res.data.data.user.id);
                localStorage.setItem("role", res.data.data.user.role);
                localStorage.setItem("name", res.data.data.user.name);
                localStorage.setItem("poli", res.data.data.user.nm_poli);
                localStorage.setItem("username", details.username);
                setNmlengkap(res.data.data.user.name);
                // localStorage.setItem("password", values.password);

                // if (
                //   details.username === res.data.data.user.email &&
                //   details.password === res.data.data.user.password
                // ) {
                //   console.log("Logged in");
                //   setUser({ username: details.username, password: details.password });
                //   setIslogin(true);
                // } else {
                //   console.log("Details not match");
                //   setError("Details not match");
                // }

                setUser({
                    username: details.username,
                    password: details.password,
                });
                setIslogin(true);
                message.success("Login Success");
                // console.log("Logged in");
                // navigate("/home");
                // console.log(res);
            })
            .catch((err) => {
                console.log(err);
                localStorage.setItem("islogin", false);
                message.error("Login Fail..!");
                setError("Details not match");
            });
    };

    const Logout = () => {
        console.log("logout");
        // setUser({ username: "", password: "" });
        setIslogin(false);
        localStorage.setItem("islogin", false);
    };

    function useWindowSize() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);

                if (window.innerWidth <= 569) {
                    setLebarSider(0);
                    setCollapsed(true);
                } else if (
                    window.innerWidth > 569 &&
                    window.innerWidth <= 1109
                ) {
                    setLebarSider(80);
                    setCollapsed(true);
                } else {
                    setCollapsed(false);
                }
            }
            window.addEventListener("resize", updateSize);
            updateSize();
            return () => window.removeEventListener("resize", updateSize);
        }, []);
        return size;
    }

    const [height, width] = useWindowSize();

    //DRAWER
    const showDrawer = () => {
        setVisibleDrawer(true);
    };

    const onCloseDrawer = () => {
        setVisibleDrawer(false);
    };

    //END DRAWER

    return (
        <Layout className="layout">
            {!islogin && ""}
            {islogin && (
                <Header style={{ backgroundColor: "#82D7D2" }}>
                    {/* TOMBOL TOGGLE */}
                    {/* JIKA LEBAR LAYAR DIATAS 569 */}
                    {window.innerWidth > 569 &&
                        React.createElement(
                            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: "trigger",
                                onClick: () => setCollapsed(!collapsed),
                            }
                        )}
                    {/* END LAYAR DIATAS 569 */}

                    {/* JIKA LEBAR LAYAR DIBAWAH AT SAMA 569 */}
                    {window.innerWidth <= 569 &&
                        React.createElement(
                            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                            {
                                className: "trigger-dua",
                                onClick: () => showDrawer(),
                            }
                        )}
                    {/* END LAYAR DIBAWAH 569 */}
                    {/* END TOMBOL TOGGLE */}

                    {/* LOGO */}
                    {window.innerWidth <= 569 || (
                        <div className="logo">
                            <Title level={5} style={{ color: "white" }}>
                                KLINIK SEDAYU
                            </Title>
                        </div>
                    )}
                    {/* END LOGO */}

                    {/* BUTTON LOGGOUT */}
                    <Link to="/login" onClick={Logout}>
                        <Avatar icon={<UserOutlined />} className="avatar" />
                    </Link>
                    <div className="username">{nmlengkap}</div>
                    {/* END BUTTON LOGGOUT */}
                </Header>
            )}
            <Layout>
                {!islogin && ""}
                {islogin && (
                    <Sider
                        width={200}
                        className="site-layout-background"
                        breakpoint="lg"
                        collapsedWidth={lebarSider}
                        trigger={null}
                        collapsible
                        collapsed={collapsed}
                    >
                        <MyNavigator />
                    </Sider>
                )}
                <Layout>
                    <Content>
                        <div className="site-layout-content">
                            <Routes>
                                {!islogin && (
                                    <Route
                                        path="/login"
                                        element={
                                            <Loginform
                                                Login={Login}
                                                baseUrl={baseUrl}
                                            />
                                        }
                                    />
                                )}
                                {islogin && (
                                    <>
                                        <Route
                                            path="/"
                                            element={<Home baseUrl={baseUrl} />}
                                        />
                                        <Route
                                            path="/regpasien"
                                            element={
                                                <RegistrasiPasien
                                                    baseUrl={baseUrl}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/polimata"
                                            element={
                                                <PoliMata baseUrl={baseUrl} />
                                            }
                                        />

                                        {/* Apotik */}
                                        <Route
                                            path="/supplier"
                                            element={
                                                <Supplier baseUrl={baseUrl} />
                                            }
                                        />
                                        <Route
                                            path="/stock"
                                            element={<Obat baseUrl={baseUrl} />}
                                        />
                                        <Route
                                            path="/in"
                                            element={
                                                <BrgIn baseUrl={baseUrl} />
                                            }
                                        />
                                        <Route
                                            path="/out"
                                            element={
                                                <BrgOut baseUrl={baseUrl} />
                                            }
                                        />

                                        <Route
                                            path="/register"
                                            element={
                                                <RegistrasiUser
                                                    baseUrl={baseUrl}
                                                />
                                            }
                                        />
                                        <Route
                                            path="/pasienedit"
                                            element={
                                                <FormPasienEdit
                                                    baseUrl={baseUrl}
                                                />
                                            }
                                        />
                                    </>
                                )}
                                <Route
                                    path="*"
                                    element={
                                        <Navigate
                                            to={islogin ? "/" : "/login"}
                                        />
                                    }
                                />
                            </Routes>
                        </div>
                    </Content>
                </Layout>
            </Layout>
            <Drawer
                title="My OPNAME"
                placement="left"
                width={250}
                onClose={onCloseDrawer}
                visible={visibleDrawer}
            >
                <MyNavigator closeDrawer={onCloseDrawer} />
            </Drawer>
        </Layout>
    );
};

export default App;
