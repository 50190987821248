import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Select, Typography } from "antd";
import axios from "axios";

const { Option } = Select;
const { Title } = Typography;

const RegistrasiUser = (props) => {
  const [form] = Form.useForm();

  const baseUrl = props.baseUrl;//"http://192.168.1.71:8000/api";

  const [loadings, setLoadings] = useState(false);
  const [isDokter, setDokter] = useState(false);

  const onRoleChange = (value) => {
    console.log(value);
    switch (value) {
      case "2":
        setDokter(true);
        return;

      case "3":
        setDokter(false);
        return;

      default:
        return;
    }
  };

  const onFinish = async (v) => {
    // console.log(v);
    setLoadings(true);
    var data = {
      email: v.email,
      password: v.password,
      name: v.name,
      role: v.role,
      nm_poli: v.nm_poli,
    };
    await axios
      .post(baseUrl + "/register", data)
      .then((res) => {
        if (res.status === 201) {
          message.success("Registrasi berhasil");
          setLoadings(false);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Registrasi gagal");
        setLoadings(false);
      });
  };

  const onFinishFailed = () => {
    setLoadings(true);
    setLoadings(false);
  };
  return (
    <div>
      <Title level={4} style={{ paddingBottom: "20px" }}>
        Registrasi User
      </Title>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 7 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        // autocomplete="off"
        // initialValues={{ nm_poli: props.poli }}
      >
        <Form.Item
          label="Username"
          name="email"
          rules={[{ required: true, message: "Masukkan username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Masukkan password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Nama Lengkap"
          name="name"
          rules={[{ required: true, message: "Masukkan nama lengkap!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="role" label="Jabatan" rules={[{ required: true }]}>
          <Select
            placeholder="Pilih Jabatan"
            onChange={onRoleChange}
            allowClear
          >
            {/* <Option value="1">admin</Option> */}
            <Option value="2">Dokter</Option>
            <Option value="3">Staff</Option>
          </Select>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.role !== currentValues.role
          }
        >
          {({ getFieldValue }) =>
            getFieldValue("role") === "2" ? (
              <Form.Item
                name="nm_poli"
                label="Poli"
                rules={[{ required: isDokter }]}
              >
                <Select
                  placeholder="Pilih Poli"
                  // onChange={onGenderChange}
                  allowClear
                >
                  <Option value="MATA">MATA</Option>
                  <Option value="GIGI">GIGI</Option>
                </Select>
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 3, span: 7 }}>
          <Button type="primary" htmlType="submit" loading={loadings}>
            Registrasi
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default RegistrasiUser;
