import React, { useEffect, useState } from "react";
import { Menu } from "antd";
import {
    AppstoreOutlined,
    VideoCameraOutlined,
    SettingOutlined,
    CustomerServiceOutlined,
    RadiusUpleftOutlined,
    UnorderedListOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

const getItem = (key, label, icon, children) => {
    return {
        key,
        label,
        icon,
        children,
    };
};

const MyNavigator = ({ closeDrawer }) => {
    const [role, setRole] = useState(localStorage.getItem("role"));

    useEffect(() => {
        setRole(localStorage.getItem("role"));
    }, [role]);

    const menuAdmin = [
        getItem("1", <Link to="/">Dashboard</Link>, <AppstoreOutlined />),
        getItem(
            "2",
            <Link to="/regpasien">Registrasi Pasien</Link>,
            <VideoCameraOutlined />
        ),
        getItem(
            "3",
            <Link to="/polimata">Pelayanan Poli</Link>,
            <CustomerServiceOutlined />
        ),
        getItem("4", "Apotik", <RadiusUpleftOutlined />, [
            getItem("5", <Link to="/supplier">Master Supplier</Link>),
            getItem("6", <Link to="/stock">Master Obat & Stock</Link>),
            getItem("7", <Link to="/kartustock">Kartu Stock</Link>),
            getItem("8", "Transaksi", <RadiusUpleftOutlined />, [
                getItem("9", <Link to="/in">Barang Masuk</Link>),
                getItem("10", <Link to="/out">Barang Keluar</Link>),
            ]),
        ]),
        getItem(
            "11",
            <Link to="/register">Registrasi User</Link>,
            <SettingOutlined />
        ),
    ];

    const menuDokter = [
        getItem("1", <Link to="/">Dashboard</Link>, <AppstoreOutlined />),
        getItem(
            "2",
            <Link to="/polimata">Pelayanan Poli</Link>,
            <CustomerServiceOutlined />
        ),
        getItem("4", "Apotik", <RadiusUpleftOutlined />, [
            // getItem("5", <Link to="/in">Barang Masuk</Link>),
            // getItem("6", <Link to="/out">Barang Keluar</Link>),
            getItem("7", <Link to="/stock">Stock Obat</Link>),
        ]),
    ];

    const menuStaff = [
        getItem("1", <Link to="/">Dashboard</Link>, <AppstoreOutlined />),
        getItem(
            "2",
            <Link to="/regpasien">Registrasi Pasien</Link>,
            <VideoCameraOutlined />
        ),
        getItem("4", "Apotik", <RadiusUpleftOutlined />, [
            getItem("5", <Link to="/in">Barang Masuk</Link>),
            getItem("6", <Link to="/out">Barang Keluar</Link>),
            getItem("7", <Link to="/stock">Stock Obat</Link>),
        ]),
    ];

    return (
        <div>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={["1"]}
                style={{ height: "89vh" }}
                onClick={closeDrawer}
                items={
                    role === "1"
                        ? menuAdmin
                        : role === "2"
                        ? menuDokter
                        : menuStaff
                }
            />
            {/* <Menu.Item key="1" icon={<AppstoreOutlined />}>
          <Link to="/">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<VideoCameraOutlined />}>
          <Link to="/regpasien">Registrasi Pasien</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<SettingOutlined />}>
          <Link to="/polimata">Pelayanan Poli Mata</Link>
        </Menu.Item>
      </Menu> */}
        </div>
    );
};

export default MyNavigator;
