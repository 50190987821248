import React from "react";
import { Card } from "antd";
import GridSupplier from "../../../component/GridSupplier/index.tsx";

const Supplier = (props) => {
  const baseUrl = props.baseUrl;

  return (
    <div>
      <Card size="small" title="Master Supplier">
        <GridSupplier baseUrl={baseUrl} />
      </Card>
    </div>
  );
};

export default Supplier;
