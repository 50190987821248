import React, { useEffect, useState } from "react";
import "./styleGridPasien.css";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Toolbar,
  Search,
  Inject,
  CommandColumn,
  Grid,
  Page,
} from "@syncfusion/ej2-react-grids";
import { getPasien } from "../../../config/api/apiServices";
import Moment from "moment";
import { useNavigate } from "react-router-dom";
import { registerLicense } from "@syncfusion/ej2-base";

// Registering Syncfusion license key
registerLicense(
  "ORg4AjUWIQA/Gnt2VVhiQlFadVlJXmJWf1FpTGpQdk5yd19DaVZUTX1dQl9hSXlTckVmXHtfcHNVRGM="
);

const GridPasien = (props) => {
  let navigate = useNavigate();
  let grid: Grid | null;

  const toolbarku = [
    "Search",
    {
      text: "Tambah Pasien Baru",
      tooltipText: "Tambah Pasien Baru",
      prefixIcon: "e-add",
      id: "addButton",
    },
  ];

  //api services
  const [data, setData] = useState();
  useEffect(() => {
    let isMounted = true;
    getPasien(props.poli).then((data) => {
      if (isMounted) setData(data);
    });
    return () => {
      isMounted = false;
    };
  }, [data]);

  const commands = [
    {
      buttonOption: {
        // iconCss: " e-icons e-play",
        cssClass: "e-warning",
        content: "Ambil Antrian",
      },
    },
    {
      buttonOption: {
        // iconCss: " e-icons e-play",
        cssClass: "e-danger",
        content: "rekam",
      },
    },
    {
      buttonOption: {
        iconCss: " e-icons e-edit",
        cssClass: "e-primary",
        // content: "edit",
        backgroundColor: "red",
      },
    },
  ];

  const commandClick = (args) => {
    if (grid) {
      // console.log(args);
      props.setNoKartuPasien(args.rowData.no_kartu_pasien);
      props.setNamaPasien(args.rowData.nm_pasien);
      props.setAlamat(args.rowData.alamat);
      props.setPoli(args.rowData.nm_poli);
      props.setTglLahir(Moment(args.rowData.tgl_lahir).format("DD MMM YYYY"));

      if (args.commandColumn.buttonOption.content === "Ambil Antrian") {
        props.onKeyChange("4"); //TAB 4 yang aktif
        // console.log("antrian");
      } else if (args.commandColumn.buttonOption.content === "rekam") {
        props.onKeyChange("6"); //TAB 6 yang aktif
        // console.log("rekam");
      } else if (
        args.commandColumn.buttonOption.iconCss === " e-icons e-edit"
      ) {
        // console.log(Moment(args.rowData.tgl_lahir).format("DD-MM-YYYY"));

        navigate("/pasienedit", {
          state: {
            id: args.rowData.id,
            nokartu: args.rowData.no_kartu_pasien,
            poli: args.rowData.nm_poli,
            nik: args.rowData.nik,
            nmpasien: args.rowData.nm_pasien,
            tgllahir: Moment(args.rowData.tgl_lahir).format("DD-MM-YYYY"),
            gender: args.rowData.jns_kel,
            alamat: args.rowData.alamat,
            nohp: args.rowData.no_hp,
          },
        });
        // console.log("edit");
      }
      //  else if (args.CommandColumn.buttonOption.content === "Rekam Medis") {
      //   // props.onKeyChange("6"); //TAB 6 yang aktif
      //   console.log("medis");
      // } else if (args.CommandColumn.buttonOption.content === "Edit") {
      //   console.log("edit");
      //   // navigate("/pasienedit", {
      //   //   state: { nokartu: mydata.nokartu, poli: mydata.nmpasien },
      //   // });
      // }
    }
  };

  // const rowSelected = () => {
  //   if (grid) {
  //     let data = grid.getSelectedRecords()[0];
  //     props.setNoKartuPasien(data.no_kartu_pasien);
  //     props.setNamaPasien(data.nm_pasien);
  //     props.setAlamat(data.alamat);
  //     props.setPoli(data.nm_poli);
  //     props.setTglLahir(Moment(data.tgl_lahir).format("DD MMM YYYY"));
  //   }
  // };

  const toolbarClick = (args) => {
    if (grid) {
      if (args.item.id === "addButton") {
        // console.log("tombol add");
        props.onKeyChange("2"); //TAB 2 yang aktif
      }
    }
  };

  return (
    <div>
      <GridComponent
        ref={(g) => (grid = g)}
        dataSource={data}
        height="200px"
        allowPaging={true}
        pageSettings={{ pageSize: 10, pageCount: 5, pageSizes: true }}
        toolbar={toolbarku}
        // commandClick={commandClick}
        toolbarClick={toolbarClick}
        // rowSelected={rowSelected}
        commandClick={commandClick}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            headerText="ID"
            // textAlign="center"
            width="100"
          />
          <ColumnDirective
            // field="id"
            headerText="Command"
            textAlign="center"
            width="290"
            commands={commands}
          />

          <ColumnDirective
            field="nm_poli"
            headerText="POLI"
            // textAlign="center"
            width="120"
          />
          <ColumnDirective
            field="no_kartu_pasien"
            headerText="NO. KARTU"
            // textAlign="center"
            width="120"
          />

          {/* <ColumnDirective
            // field="id"
            headerText="Command"
            // textAlign="left"
            width="170"
            commands={commandsTwo}
          /> */}

          <ColumnDirective
            field="nik"
            headerText="NIK"
            // textAlign="center"
            width="200"
          />

          <ColumnDirective
            field="nm_pasien"
            headerText="NAMA PASIEN"
            // textAlign="center"
            width="300"
          />

          <ColumnDirective
            field="tgl_lahir"
            headerText="TGL. LAHIR"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="jns_kel"
            headerText="GENDER"
            // textAlign="center"
            width="120"
          />
          <ColumnDirective
            field="alamat"
            headerText="ALAMAT"
            // textAlign="center"
            width="300"
          />
          <ColumnDirective
            field="no_hp"
            headerText="NO. HP"
            // textAlign="center"
            width="120"
          />

          {/* <ColumnDirective
            // field="id"
            headerText="Aksi"
            // textAlign="left"
            width="170"
            commands={commandsEdit}
          /> */}
        </ColumnsDirective>
        <Inject services={[Search, Toolbar, CommandColumn, Page]} />
      </GridComponent>
    </div>
  );
};

export default GridPasien;
