import { Tabs, Typography } from "antd";
import React, { useState } from "react";
import GridAntrianForDoctor from "../../../component/Pasien/GridAntrianForDoctor/index.tsx";
import GridCheckup from "../../../component/Pasien/GridCheckup/index.tsx";
import GridPasienForDoctor from "../../../component/Pasien/GridPasienForDoctor/index.tsx";
import GridRekamMata from "../../../component/RekamMedis/GridRekamMata/index.tsx";

const { TabPane } = Tabs;
const { Title } = Typography;

const PoliMata = (props) => {
  const baseUrl = props.baseUrl;
  const [activeKey, setActiveKey] = useState("1");

  const [noAntrian, setnoAntrian] = useState();
  const [noKartu, setnoKartu] = useState();
  const [nmPasien, setnmPasien] = useState();
  const [tglLahir, setTglLahir] = useState();
  const [alamat, setAlamat] = useState();
  const poli = localStorage.getItem("poli");

  const onKeyChange = (key) => {
    setActiveKey(key);
  };

  const ambilNoAntrian = (a) => {
    // console.log(a);
    setnoAntrian(a);
  };
  const ambilNoKartuPasien = (a) => {
    // console.log(a);
    setnoKartu(a);
  };

  const ambilNamaPasien = (a) => {
    // console.log(a);
    setnmPasien(a);
  };
  const ambilTglLahir = (a) => {
    // console.log(a);
    setTglLahir(a);
  };
  const ambilAlamat = (a) => {
    // console.log(a);
    setAlamat(a);
  };

  return (
    <div>
      <Title level={4}>POLI {poli}</Title>

      <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onKeyChange}>
        <TabPane tab="ANTRIAN PASIEN" key="1">
          <GridAntrianForDoctor
            onKeyChange={onKeyChange}
            setNoAntrian={ambilNoAntrian}
            setNoKartuPasien={ambilNoKartuPasien}
            setNamaPasien={ambilNamaPasien}
            setTglLahir={ambilTglLahir}
            setAlamat={ambilAlamat}
            baseUrl={baseUrl}
          />
        </TabPane>
        <TabPane tab="REKAM MEDIS" key="2">
          <GridRekamMata
            noAntrian={noAntrian}
            noKartuPasien={noKartu}
            nmPasien={nmPasien}
            tglLahir={tglLahir}
            alamat={alamat}
            baseUrl={baseUrl}
          />
        </TabPane>
        <TabPane tab="LIST CHECKUP HARI INI" key="3">
          <GridCheckup
            onKeyChange={onKeyChange}
            setNoAntrian={ambilNoAntrian}
            setNoKartuPasien={ambilNoKartuPasien}
            setNamaPasien={ambilNamaPasien}
            setTglLahir={ambilTglLahir}
            setAlamat={ambilAlamat}
            baseUrl={baseUrl}
          />
        </TabPane>
        <TabPane tab="MY PASIEN" key="4">
          <GridPasienForDoctor
            onKeyChange={onKeyChange}
            setNoAntrian={ambilNoAntrian}
            setNoKartuPasien={ambilNoKartuPasien}
            setNamaPasien={ambilNamaPasien}
            setTglLahir={ambilTglLahir}
            setAlamat={ambilAlamat}
            baseUrl={baseUrl}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default PoliMata;
