import axios from "axios";

// const baseUrl = "http://192.168.1.71:8000/api";
const baseUrl = "https://lumen8apipasien.gg-ssp.com/api";
// const baseUrl = "http://localhost:8000/api";

//api pasien
const getPasien = (a) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    return axios.get(baseUrl + `/pasien/${a}`).then((res) => res.data.data);
};

//api pendaftaran

const getPendaftaran = (tgldaftar, poli) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    return axios
        .get(baseUrl + `/daftar/${tgldaftar}/${poli}`)
        .then((res) => res.data);
};

const getCheckup = (tgldaftar, poli) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    return axios
        .get(baseUrl + `/checkup/${tgldaftar}/${poli}`)
        .then((res) => res.data);
};

const genNoAntrian = async (thn, bln, tgl, poli) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    return await axios
        .get(baseUrl + `/daftar/gennoantri/${thn}/${bln}/${tgl}/${poli}`)
        .then((res) => res.data);
};

const getCetakNomorAntrian = async (nomor) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    return await axios
        .get(baseUrl + `/daftar/cetaknoantrian/${nomor}`)
        .then((res) => res.data);
};

// const deletePendaftaran = (id) => {
//   return axios
//     .delete(baseUrl + "/daftar/" + id)
//     .then((res) => console.log(res.data));
// };

const putUpdateStatus = (a, b, c, d) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    var data = {
        no_antrian: a,
        no_kartu_pasien: b,
        status_aktivitas: c,
        user_id: d,
    };
    return axios.put(baseUrl + "/daftar/update", data).then((res) => res.data);
};

//api rekam medis [POLI MATA]
const postRekamMata = (a) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    var data = {
        no_kartu_pasien: a,
    };
    return axios.post(baseUrl + "/rekammata", data).then((res) => res.data);
};

const putPoliMata = (a, b, c, d, e, f, g, h) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    var data = {
        id: a,
        no_antrian: b,
        no_kartu_pasien: c,
        penyakit: d,
        diagnosa: e,
        theraphy: f,
        ket: g,
        user_id: h,
    };
    return axios
        .put(baseUrl + "/polimata/update", data)
        .then((res) => res.data);
};

const deleteRekamMata = (noantri, nokartu) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    return axios
        .delete(baseUrl + `/polimata/delete/${noantri}/${nokartu}`)
        .then((res) => res.data);
};

const destroy = (id) => {
    // return fetch(baseUrl + "/kapal").then((res) => res.json());
    return axios
        .delete(baseUrl + `/polimata/delete/${id}`)
        .then((res) => res.data);
};

export {
    getPasien,
    getPendaftaran,
    genNoAntrian,
    getCetakNomorAntrian,
    // deletePendaftaran,
    putUpdateStatus,
    postRekamMata,
    putPoliMata,
    deleteRekamMata,
    destroy,
    getCheckup,
};
