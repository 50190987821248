import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Toolbar,
  Inject,
  Grid,
  CommandColumn,
  Search,
} from "@syncfusion/ej2-react-grids";
import { getCheckup, putUpdateStatus } from "../../../config/api/apiServices";
import Moment from "moment";
import { message } from "antd";

const GridCheckup = (props) => {
  ///ambil data dari localstorage
  const [poli, setPoli] = useState(localStorage.getItem("poli"));

  const [tgldaftar, setTgldaftar] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );

  useEffect(() => {
    const u = localStorage.getItem("poli");
    setPoli(u);
  }, [poli]);

  //api services
  const [data, setData] = useState();
  useEffect(() => {
    let isMounted = true;
    getCheckup(tgldaftar, poli).then((data) => {
      if (isMounted) setData(data);
    });
    return () => {
      isMounted = false;
    };
  }, [data]);

  let grid: Grid | null;
  const commandClick = (args) => {
    if (grid) {
      if (args.commandColumn.buttonOption.iconCss === "e-icons e-edit") {
        props.setNoAntrian(args.rowData.no_antrian);
        props.setNoKartuPasien(args.rowData.no_kartu_pasien);
        props.setNamaPasien(args.rowData.nm_pasien);
        props.setTglLahir(Moment(args.rowData.tgl_lahir).format("DD-MM-YYYY"));
        props.setAlamat(args.rowData.alamat);
        props.onKeyChange("2"); //TAB 2 yang aktif
      }
    }
  };

  //tombol pada grid
  const commands = [
    {
      // type: "Edit",
      buttonOption: {
        iconCss: "e-icons e-edit",
        cssClass: "e-flat",
      },
    },
  ];

  const toolbarku = [
    "Search",
  ];

  return (
    <div>
      <GridComponent
        ref={(g) => (grid = g)}
        dataSource={data}
        height="200px"
        commandClick={commandClick}
        toolbar={toolbarku}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="noAntri"
            headerText="NO. ANTRI"
            // textAlign="center"
            width="100"
          />

          <ColumnDirective
            field="no_kartu_pasien"
            headerText="NO. KARTU"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="nm_pasien"
            headerText="NAMA PASIEN"
            // textAlign="center"
            width="300"
          />

          <ColumnDirective
            field="nm_poli"
            headerText="POLI"
            // textAlign="center"
            width="200"
          />

          {/* <ColumnDirective
            field="status_aktivitas"
            headerText="STATUS"
            // textAlign="center"
            width="200"
          /> */}

          <ColumnDirective
            // field="id"
            headerText="Aksi"
            // textAlign="center"
            // width="100"
            commands={commands}
          />
        </ColumnsDirective>
        <Inject services={[Search, Toolbar, CommandColumn]} />
      </GridComponent>
    </div>
  );
};

export default GridCheckup;
