import React, { Component } from "react";
import "./styleprint.css";
import { Space, Typography } from "antd";
import moment from "moment";

const { Title } = Typography;

class ComponentToPrint extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentDate: moment(new Date()).format("DD-MM-YYYY H:mm:ss"),
    };
  }
  componentDidMount() {
    setInterval(() => {
      const tgl = new Date();
      this.setState({
        currentDate: moment(tgl).format("DD-MM-YYYY H:mm:ss"),
      });
    }, 1000);
  }

  render() {
    // console.log(this.props);
    return (
      <div>
        <div className="posisi">
          <Title className="posisi-poli" level={5}>
            Poli {this.props.poli}
          </Title>
          <Space className="posisi-waktu">{this.state.currentDate}</Space>
          <Title className="posisi-nomor" level={1}>
            {this.props.noAntri}
          </Title>
          <Title level={5} className="posisi-klinik">
            KLINIK SEDAYU
          </Title>
        </div>
      </div>
    );
  }
}

export default ComponentToPrint;
