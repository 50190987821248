import { Button, Space, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const ListPoli = (props) => {
  const handleClickMata = () => {
    props.setPoli("MATA");
    props.onKeyChange("3"); //TAB 3 yang aktif
    localStorage.setItem("poli", "MATA");
  };
  const handleClickGigi = () => {
    props.setPoli("GIGI");
    props.onKeyChange("3"); //TAB 3 yang aktif
    localStorage.setItem("poli", "GIGI");
  };

  return (
    <div>
      <Space>
        <Button type="dashed" size="large" onClick={handleClickMata}>
          POLI MATA
        </Button>
        <Button type="dashed" size="large" onClick={handleClickGigi}>
          POLI GIGI
        </Button>
        <div style={{ color: "red" }}>Hubungi Vendor untuk menambah poli</div>
      </Space>
    </div>
  );
};

export default ListPoli;
