import React from "react";
import { Button, Form, Input, Space } from "antd";
import { useNavigate } from "react-router-dom";

import "./loginform.css";

const Loginform = ({ Login, error }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    Login(values);
    // console.log(values);
    localStorage.setItem("islogin", true);
    navigate("/home");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: "100%", height: "100vh", justifyContent: "center" }}
      >
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "center" }}
        >
          <h1 style={{ marginBottom: "30px" }}>LOGIN</h1>
        </Space>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 4 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {error !== "" ? <div>{error}</div> : ""}
          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>
          {/* <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{ offset: 8, span: 16 }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}
          <Form.Item wrapperCol={{ offset: 10, span: 4 }}>
            <Button type="primary" htmlType="submit">
              Login
            </Button>
          </Form.Item>
        </Form>
      </Space>
    </>
  );
};

export default Loginform;
