import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  CommandColumn,
  Grid,
  GridComponent,
  Inject,
  Search,
  Toolbar,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Space,
  Spin,
} from "antd";
import axios from "axios";
import GridObat from "../GridObat/index.tsx";
import moment from "moment";

// eslint-disable-next-line @typescript-eslint/no-redeclare
const { TextArea } = Input;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

const GridBrgOut = (props) => {
  let baseUrl = props.baseUrl;
  let grid: Grid | null;
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();

  const [isLoading, setisLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dataSupplier, setDataSupplier] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalUpdateVisible, setIsModalUpdateVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [isModalSearch, setIsModalSearch] = useState(false);
  const [recordId, setRecordId] = useState();
  const [isSave, setIsSave] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [selectedObat, setSelectedObat] = useState([]);

  ///mengambil data supplier
  useEffect(() => {
    let isMounted = true;

    axios
      .get(baseUrl + "/supplier")
      .then((res) => {
        if (isMounted) {
          setisLoading(false);
          setDataSupplier(res.data);
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, [dataSupplier]);

  ///mengambil data transaksi keluar
  useEffect(() => {
    let isMounted = true;

    axios
      .get(baseUrl + `/out/${props.dates[0]}/${props.dates[1]}`)
      .then((res) => {
        if (isMounted) {
          setisLoading(false);
          setData(res.data);
        }
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err);
      });

    return () => {
      isMounted = false;
    };
  }, [data]);

  const getSelectedObat = (a) => {
    // console.log(a);
    setSelectedObat(a);
  };

  const onSearch = () => {
    setIsModalSearch(true);
  };

  const handleOkSearch = () => {
    // console.log(selectedObat.kd_brg);

    setIsModalSearch(false);
    form.setFieldsValue({
      kd_brg: selectedObat.kd_brg,
      nm_brg: selectedObat.nm_brg,
      satuan: selectedObat.satuan,
      hrg_beli: selectedObat.hrg_jual,
    });

    formUpdate.setFieldsValue({
      kd_brg: selectedObat.kd_brg,
      nm_brg: selectedObat.nm_brg,
      satuan: selectedObat.satuan,
      hrg_beli: selectedObat.hrg_jual,
    });
  };

  const handleCancelSearch = () => {
    setIsModalSearch(false);
  };

  const toolbarku = [
    {
      text: "Add",
      // tooltipText: "Tambah Kapal",
      prefixIcon: "e-add",
      id: "addButton",
    },
    {
      text: "Edit",
      // tooltipText: "Ubah Kapal",
      prefixIcon: "e-edit",
      id: "editButton",
    },
    {
      text: "Delete",
      // tooltipText: "Hapus Kapal",
      prefixIcon: "e-delete",
      id: "deleteButton",
    },
    "ExcelExport",
    "PdfExport",
    "Search",
  ];

  const toolbarClick = (args) => {
    if (grid) {
      if (args.item.id === "addButton") {
        // console.log("tombol add");
        setIsModalVisible(true);
      } else if (args.item.id === "editButton") {
        // console.log("tombol edit");
        setIsModalUpdateVisible(true);
      } else if (args.item.id === "deleteButton") {
        // console.log("tombol delete");
        if (data.length > 0) {
          setIsModalDeleteVisible(true);
        } else {
          message.warning("No record to delete");
        }
      } else if (args.item.id.includes("excelexport")) {
        grid.excelExport();
      } else if (args.item.id.includes("pdfexport")) {
        grid.pdfExport({
          fileName: "barang-keluar.pdf",
          pageOrientation: "Landscape",
          theme: {
            header: {
              bold: true,
              fontColor: "#000000",
              fontName: "Calibri",
              fontSize: 8,
            },
          },
          //  header:{
          //   fromTop:0,
          //   height:130,
          //   contents:[{
          //     type:'Text',
          //     value:'Northwind Traders',
          //     position:{x:0, y:50},
          //     style:{textBrushColor:'000000',fontSize:13}
          //   }]
          //  }
        });
      }
    }
  };

  const rowSelected = () => {
    if (grid) {
      let record = grid.getSelectedRecords()[0];
      // console.log(record);
      setRecordId(record.id);
      formUpdate.setFieldsValue({
        id: record.id,
        tanggal: moment(record.tanggal, dateFormat),
        no_ref: record.no_ref,
        kd_supplier: record.kd_supplier,
        kd_brg: record.kd_brg,
        nm_brg: record.nm_brg,
        satuan: record.satuan,
        qty_out: record.qty_out,
        hrg_beli: record.hrg_beli,
        ket: record.ket,
        user_id: localStorage.getItem("id"),
      });
    }
  };

  const onFinish = (v) => {
    console.log(v);

    setIsSave(true);
    let data = {
      tanggal: moment(v.tanggal).format("YYYY-MM-DD"),
      no_ref: v.no_ref,
      kd_supplier: v.kd_supplier,
      kd_brg: v.kd_brg,
      qty_out: v.qty_out,
      hrg_beli: v.hrg_beli,
      ket: v.ket,
      user_id: localStorage.getItem("id"),
    };

    axios
      .post(baseUrl + "/out", data)
      .then(() => {
        console.log("berhasil menambah data");
        message.success("Berhasil menambah data");
        setIsSave(false);
        setIsModalVisible(false);
        form.resetFields();
      })
      .catch((e) => {
        // console.log("gagal menambah data");
        console.log(e);
        message.error(e.message);
        setIsSave(false);
        setIsModalVisible(false);
      });
  };

  const onFinishFailed = (v) => {
    console.log(v);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsModalDeleteVisible(false);
    setIsModalUpdateVisible(false);
    form.resetFields();
    formUpdate.resetFields();
  };

  const onFinishUpdate = (v) => {
    setIsSave(true);
    let data = {
      tanggal: moment(v.tanggal).format("YYYY-MM-DD"),
      no_ref: v.no_ref,
      kd_supplier: v.kd_supplier,
      kd_brg: v.kd_brg,
      qty_out: v.qty_out,
      hrg_beli: v.hrg_beli,
      ket: v.ket,
      user_id: localStorage.getItem("id"),
    };

    axios
      .put(baseUrl + `/out/${recordId}`, data)
      .then(() => {
        console.log("berhasil merubah data");
        message.success("Berhasil merubah data");
        setIsSave(false);
        setIsModalUpdateVisible(false);
        formUpdate.resetFields();
      })
      .catch((e) => {
        console.log("gagal merubah data");
        console.log(e);
        message.error(e.message);
        setIsSave(false);
        setIsModalVisible(false);
      });
  };

  const handleOkDelete = () => {
    setIsClick(true);
    axios
      .delete(baseUrl + `/transaksi/${recordId}`)
      .then((res) => {
        message.success("Data berhasil dihapus !");
        setIsClick(false);
        setIsModalDeleteVisible(false);
      })
      .catch((err) => {
        message.error("Gagal menghapus data !");
        setIsClick(false);
        setIsModalDeleteVisible(false);
      });
  };

  if (isLoading) {
    return (
      <div className="example">
        <Spin />
      </div>
    );
  } else {
    return (
      <div>
        <GridComponent
          gridLines="Both"
          dataSource={data}
          rowHeight={30}
          height="200px"
          ref={(g) => (grid = g)}
          toolbar={toolbarku}
          toolbarClick={toolbarClick}
          rowSelected={rowSelected}
          allowExcelExport={true}
          allowPdfExport={true}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              headerText="ID"
              // textAlign="center"
              width="70"
            />

            <ColumnDirective
              field="tanggal"
              headerText="TANGGAL"
              // textAlign="center"
              width="120"
            />

            <ColumnDirective
              field="no_ref"
              headerText="NO. REF"
              // textAlign="center"
              width="120"
            />

            <ColumnDirective
              field="kd_supplier"
              headerText="PASIEN"
              // textAlign="center"
              width="120"
            />

            <ColumnDirective
              field="kd_brg"
              headerText="CODE OBAT"
              // textAlign="center"
              width="120"
            />

            <ColumnDirective
              field="nm_brg"
              headerText="NAMA OBAT"
              // textAlign="center"
              width="120"
            />

            <ColumnDirective
              field="qty_out"
              headerText="Qty"
              textAlign="Right"
              width="100"
            />

            <ColumnDirective
              field="satuan"
              headerText="SATUAN"
              // textAlign="center"
              width="100"
            />

            <ColumnDirective
              field="hrg_beli"
              headerText="HARGA JUAL"
              textAlign="Right"
              width="150"
            />

            <ColumnDirective
              field="ket"
              headerText="KETERANGAN"
              // textAlign="center"
              width="200"
            />

            <ColumnDirective
              field="user_id"
              headerText="USER ID"
              // textAlign="center"
              width="120"
            />
          </ColumnsDirective>
          <Inject
            services={[Search, Toolbar, CommandColumn, ExcelExport, PdfExport]}
          />
        </GridComponent>

        {/* MODAL */}
        <Modal
          title="Input Transaksi Keluar"
          visible={isModalVisible}
          width={700}
          onCancel={() => {
            setIsModalVisible(false);
          }}
          footer={null}
          //   closable={false}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            // initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            // autoComplete="off"
            // initialValues={{ nm_poli: props.poli }}
          >
            <Form.Item
              label="Tanggal"
              name="tanggal"
              rules={[
                { required: true, message: "Masukkan tanggal transaksi !" },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="No. Ref"
              name="no_ref"
              wrapperCol={{ span: 10 }}
              rules={[
                { required: true, message: "Masukkan nomor referensi !" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Pasien"
              name="kd_supplier"
              //   rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Kode Obat"
              style={{
                marginBottom: 0,
              }}
            >
              <Form.Item
                name="kd_brg"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(30% - 8px)",
                }}
              >
                <Input readOnly style={{ backgroundColor: "#faf6ed" }} />
              </Form.Item>

              <Form.Item
                name="nm_brg"
                style={{
                  display: "inline-block",
                  width: "calc(50%)",
                  margin: "0 8px",
                }}
              >
                <Input readOnly style={{ backgroundColor: "#faf6ed" }} />
              </Form.Item>

              <Form.Item
                name="btn_cari"
                style={{
                  display: "inline-block",
                  width: "calc(10% - 8px)",
                  margin: "0 0px",
                }}
              >
                <Button type="primary" size="small" onClick={onSearch}>
                  Cari
                </Button>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Satuan" name="satuan" wrapperCol={{ span: 6 }}>
              <Input readOnly style={{ backgroundColor: "#faf6ed" }} />
            </Form.Item>

            <Form.Item
              label="Qty"
              name="qty_out"
              rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Harga Jual"
              name="hrg_beli"
              // rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <InputNumber disabled style={{ width: 200 }} />
            </Form.Item>

            <Form.Item
              label="Keterangan"
              name="ket"
              // rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 20 }}>
              <Space>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isSave}>
                  Save
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
        {/* END MODAL */}

        {/* MODAL DELETE */}
        <Modal
          title="Confirmation Delete"
          visible={isModalDeleteVisible}
          onOk={handleOkDelete}
          onCancel={handleCancel}
          confirmLoading={isClick}
          width={300}
        >
          <p>Yakin data akan dihapus ?</p>
        </Modal>
        {/* END MODAL DELETE */}

        {/* MODAL UPDATE */}
        <Modal
          title="Update Transaksi Keluar"
          visible={isModalUpdateVisible}
          onCancel={() => {
            setIsModalUpdateVisible(false);
          }}
          footer={null}
          //   closable={false}
        >
          <Form
            form={formUpdate}
            name="basic-update"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 20 }}
            // initialValues={{ remember: true }}
            onFinish={onFinishUpdate}
            onFinishFailed={onFinishFailed}
            // autoComplete="off"
            // initialValues={{ nm_poli: props.poli }}
          >
            <Form.Item
              hidden
              wrapperCol={{ span: 6 }}
              label="id"
              name="id"
              // rules={[{ required: true, message: "Masukkan kode supplier !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Tanggal"
              name="tanggal"
              rules={[
                { required: true, message: "Masukkan tanggal transaksi !" },
              ]}
            >
              <DatePicker />
            </Form.Item>

            <Form.Item
              label="No. Ref"
              name="no_ref"
              wrapperCol={{ span: 10 }}
              rules={[
                { required: true, message: "Masukkan nomor referensi !" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Pasien"
              name="kd_supplier"
              //   rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Kode Obat"
              style={{
                marginBottom: 0,
              }}
            >
              <Form.Item
                name="kd_brg"
                rules={[
                  {
                    required: true,
                  },
                ]}
                style={{
                  display: "inline-block",
                  width: "calc(30% - 8px)",
                }}
              >
                <Input readOnly style={{ backgroundColor: "#faf6ed" }} />
              </Form.Item>

              <Form.Item
                name="nm_brg"
                style={{
                  display: "inline-block",
                  width: "calc(50%)",
                  margin: "0 8px",
                }}
              >
                <Input readOnly style={{ backgroundColor: "#faf6ed" }} />
              </Form.Item>

              <Form.Item
                name="btn_cari"
                style={{
                  display: "inline-block",
                  width: "calc(10% - 8px)",
                  margin: "0 0px",
                }}
              >
                <Button type="primary" size="small" onClick={onSearch}>
                  Cari
                </Button>
              </Form.Item>
            </Form.Item>

            <Form.Item label="Satuan" name="satuan" wrapperCol={{ span: 6 }}>
              <Input readOnly style={{ backgroundColor: "#faf6ed" }} />
            </Form.Item>

            <Form.Item
              label="Qty"
              name="qty_out"
              rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Harga Jual"
              name="hrg_beli"
              // rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <InputNumber disabled style={{ width: 200 }} />
            </Form.Item>

            <Form.Item
              label="Keterangan"
              name="ket"
              // rules={[{ required: true, message: "Masukkan nama supplier !" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 20 }}>
              <Space>
                <Button type="default" onClick={handleCancel}>
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit" loading={isSave}>
                  Update
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Modal>
        {/* END MODAL UPDATE*/}

        {/* MODAL SEARCH BARANG*/}
        <Modal
          title="Cari Obat"
          visible={isModalSearch}
          onOk={handleOkSearch}
          onCancel={handleCancelSearch}
          width={700}
        >
          <GridObat baseUrl={baseUrl} getSelectedObat={getSelectedObat} />
        </Modal>
        {/* END MODAL SEARCH BARANG*/}
      </div>
    );
  }
};

export default GridBrgOut;
