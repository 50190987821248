import React, { useEffect, useState } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Toolbar,
  Inject,
  Grid,
} from "@syncfusion/ej2-react-grids";
import {
  getPendaftaran,
  putUpdateStatus,
} from "../../../config/api/apiServices";
import Moment from "moment";
import { message } from "antd";

const GridAntrianForDoctor = (props) => {
  ///ambil data dari localstorage
  const [poli, setPoli] = useState(localStorage.getItem("poli"));

  const [tgldaftar, setTgldaftar] = useState(
    Moment(new Date()).format("YYYY-MM-DD")
  );

  useEffect(() => {
    const u = localStorage.getItem("poli");
    setPoli(u);
  }, [poli]);

  const toolbarku = [
    {
      text: "Pasien CALL",
      tooltipText: "Panggil Pasien",
      prefixIcon: "e-search",
      id: "addButton",
    },
  ];

  //api services
  const [data, setData] = useState();
  useEffect(() => {
    let isMounted = true;
    getPendaftaran(tgldaftar, poli).then((data) => {
      if (isMounted) setData(data);
    });
    return () => {
      isMounted = false;
    };
  }, [data]);

  let grid: Grid | null;
  const toolbarClick = (args) => {
    if (grid) {
      if (args.item.id === "addButton") {
        if (data.length > 0) {
          props.onKeyChange("2"); //TAB 2 yang aktif
          putUpdateStatus(
            data[0].no_antrian,
            data[0].no_kartu_pasien,
            "call",
            "1"
          );
          props.setNoAntrian(data[0].no_antrian);
          props.setNoKartuPasien(data[0].no_kartu_pasien);
          props.setNamaPasien(data[0].nm_pasien);
          props.setTglLahir(Moment(data[0].tgl_lahir).format("DD-MM-YYYY"));
          props.setAlamat(data[0].alamat);
        } else {
          console.log("Antrian pasien tidak ditemukan");
          message.error({
            content: "Maaf, antrian pasien tidak ditemukan",
            className: "custom-class",
            style: {
              marginTop: "20vh",
            },
          });
        }
      }
    }
  };

  return (
    <div>
      <GridComponent
        ref={(g) => (grid = g)}
        dataSource={data}
        height="200px"
        toolbar={toolbarku}
        toolbarClick={toolbarClick}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="noAntri"
            headerText="NO. ANTRI"
            // textAlign="center"
            width="100"
          />

          <ColumnDirective
            field="no_kartu_pasien"
            headerText="NO. KARTU"
            // textAlign="center"
            width="120"
          />

          <ColumnDirective
            field="nm_pasien"
            headerText="NAMA PASIEN"
            // textAlign="center"
            width="300"
          />

          <ColumnDirective
            field="nm_poli"
            headerText="POLI"
            // textAlign="center"
            width="200"
          />

          <ColumnDirective
            field="status_aktivitas"
            headerText="STATUS"
            // textAlign="center"
            width="200"
          />
        </ColumnsDirective>
        <Inject services={[Toolbar]} />
      </GridComponent>
    </div>
  );
};

export default GridAntrianForDoctor;
