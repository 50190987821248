import { Button, DatePicker, Form, Input, message, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import Moment from "moment";
import axios from "axios";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const { Option } = Select;

const FormPasienEdit = (props) => {
  const [form] = Form.useForm();
  const [loadings, setLoadings] = useState(false);

  const userid = localStorage.getItem("id");

  let navigate = useNavigate();

  const location = useLocation();
  console.log(location.state.id);

  useEffect(() => {
    form.setFieldsValue({
      no_kartu_pasien: location.state.nokartu,
      nm_poli: location.state.poli,
      nik: location.state.nik,
      nm_pasien: location.state.nmpasien,
      tgl_lahir: Moment(location.state.tgllahir),
      gender: location.state.gender,
      alamat: location.state.alamat,
      no_hp: location.state.nohp,
    });
  }, []);

  const baseUrl = props.baseUrl;//"http://192.168.1.71:8000/api";

  const getNoKartuPasien = (nik) => {
    return axios
      .get(baseUrl + "/getNoKartuPasien/" + nik)
      .then((res) => {
        props.setNoKartuPasien(res.data[0].no_kartu_pasien);

        props.onKeyChange("3"); //TAB 3 yang aktif
        setLoadings(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadings(false);
      });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const onFinish = (values) => {
    // console.log("Success:", values);
    setLoadings(true);
    var data = {
      nik: values.nik,
      nm_pasien: values.nm_pasien,
      tgl_lahir: Moment(values.tgl_lahir).format("YYYY-MM-DD"),
      jns_kel: values.gender,
      alamat: values.alamat,
      no_hp: values.no_hp,
      nm_poli: values.nm_poli,
      user_id: userid,
    };

    axios
      .put(baseUrl + `/pasien/${location.state.id}`, data)
      .then((res) => {
        // eslint-disable-next-line no-unused-expressions
        // console.log(res);
        message.success("Data pasien berhasil diupdate");
        setLoadings(false);
        navigate(-1);
      })
      .catch((error) => {
        // console.log(error);
        // console.log(error.response.data.nik[0]);
        message.error(error.response.data.nik[0]);
        setLoadings(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 7 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        // initialValues={{ tgl_lahir: location.state.tgllahir }}
      >
        <Form.Item
          name="nm_poli"
          label="Nama Poli"
          rules={[{ required: true }]}
        >
          <Select
            placeholder="Pilih Poli"
            // onChange={onGenderChange}
            allowClear
          >
            <Option value="GIGI">GIGI</Option>
            <Option value="MATA">MATA</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="No. Kartu Pasien"
          name="no_kartu_pasien"
          // rules={[{ required: true, message: "Masukkan alamat!" }]}
        >
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="NIK"
          name="nik"
          rules={[{ required: true, message: "Masukkan nik pasien!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Nama Pasien"
          name="nm_pasien"
          rules={[{ required: true, message: "Masukkan nama pasien!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Tanggal Lahir"
          name="tgl_lahir"
          rules={[{ required: true, message: "Masukkan tanggal lahir!" }]}
        >
          <DatePicker format={"DD-MM-YYYY"} />
        </Form.Item>

        <Form.Item name="gender" label="Gender" rules={[{ required: true }]}>
          <Select
            placeholder="Pilih jenis kelamin pasien"
            // onChange={onGenderChange}
            allowClear
          >
            <Option value="male">male</Option>
            <Option value="female">female</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Alamat"
          name="alamat"
          rules={[{ required: true, message: "Masukkan alamat!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="No. HP"
          name="no_hp"
          // rules={[{ required: true, message: "Masukkan alamat!" }]}
        >
          <Input />
        </Form.Item>

        {/* <Form.Item
          label="Poli"
          name="nm_poli"
          rules={[{ required: true, message: "Pilih nama poli!" }]}
        >
          <Select placeholder="Pilih nama Poli" allowClear>
            {listPoli.map((x, i) => (
              <Option key={i} value={x}>
                {x}
              </Option>
            ))}
          </Select>
        </Form.Item> */}

        <Form.Item wrapperCol={{ offset: 3, span: 7 }}>
          <Space>
            <Button type="primary" htmlType="submit" loading={loadings}>
              Update
            </Button>
            <Button type="secondary" onClick={handleCancel}>
              Cancel
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormPasienEdit;
